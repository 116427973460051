export default [
  {
    text: `Find $contactOrRelative()$ 's number`,
    category: 13
  },
  {
    text: `When is $contactOrRelative()$ 's birthday?`,
    category: 13
  },
  {
    text: `Call $contactOrRelative()$`,
    category: 13
  },
  {
    text: `Call $contactOrRelative()$ on speakerphone`,
    category: 13
  },
  {
    text: `Call the nearest %businessCategory%`,
    category: 13
  },
  {
    text: `Call %business%`,
    category: 13
  }
];
