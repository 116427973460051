import React, { Component, PropTypes } from 'react';

//styles
import { css } from 'aphrodite';
import styles from './styles';
import { cx } from 'utils/project';
import flex from 'styles/flex';

//components
import Icon from 'components/Icon';

class NoPhrasesFound extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div {...cx(css(styles.NoPhrasesFound), css(flex.vertical), css(flex.centerVertical))}>
        <Icon name="frown" className={css(styles.icon)} />
        <div className={css(styles.text)}>No results found.</div>
      </div>
    );
  }
}

export default NoPhrasesFound;
