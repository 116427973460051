export default [
  {
    text: 'Flight %flight%',
    category: 20
  },
  {
    text: 'Flight status of  %flight%',
    category: 20
  },
  {
    text: 'Has %flight% landed?',
    category: 20
  },
  {
    text: 'When will %flight% %landDepart% ?',
    category: 20
  }
];
