export default [
  'AC/DC',
  'Whitesnake',
  'Metallica',
  'Foo Fighters',
  `Guns N' Roses`,
  'Beatles',
  'Rolling Stones',
  'Red Hot Chili Peppers'
];
