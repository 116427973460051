import { createAction, handleActions } from 'redux-actions';
import is from 'plugins/is';
import $ from 'jquery';
import _ from 'lodash';

import { isRunningStandalone } from 'kitze-js-helpers/src/helpers/browser-helpers';
import { setProperty, toggleBoolean, setJqueryElement } from 'redux/redux-helpers';

//constants
import { ACTIONS, STATE } from './app.constants';
import { STATE as ROOT_STATE } from 'redux/rootReducer.constants';

//actions
export const setDeviceValues = createAction(ACTIONS.SET_DEVICE_VALUES);
export const setFooterStatus = createAction(ACTIONS.SET_FOOTER_STATUS);
export const openFooter = createAction(ACTIONS.OPEN_FOOTER);
export const closeFooter = createAction(ACTIONS.CLOSE_FOOTER);
export const setScrollerDom = createAction(ACTIONS.SET_SCROLLER_DOM);
export const setCategoryDom = createAction(ACTIONS.SET_CATEGORY_DOM);
export const openDrawer = createAction(ACTIONS.OPEN_DRAWER);
export const closeDrawer = createAction(ACTIONS.CLOSE_DRAWER);
export const toggleDrawer = createAction(ACTIONS.TOGGLE_DRAWER);
export const setScrollMarksCategory = createAction(ACTIONS.SET_SCROLL_MARKS_CATEGORY);
export const setCurrentScrolledCategory = createAction(ACTIONS.SET_CURRENT_CATEGORY);
export const scrolledToPickedCategory = createAction(ACTIONS.SCROLLED_TO_PICKED_CATEGORY);

export const openFooterCloseDrawer = () => dispatch => {
  dispatch(openFooter());
  dispatch(closeDrawer());
};

export const selectCategory = pickedCategory => (dispatch, getState) => {
  if (pickedCategory !== -1 && pickedCategory !== undefined) {
    dispatch(openDrawer(false));

    const state = getState();
    const categories = state[ROOT_STATE.APP][STATE.CATEGORIES_DOM];
    const category = _.find(categories, category => parseInt(category.dataset.categoryId) === pickedCategory);

    if (category !== undefined) {
      const $category = $(category);
      const $scroller = state[ROOT_STATE.APP][STATE.SCROLLER_DOM];
      const offset = $scroller.scrollTop() + $category.position().top;

      $scroller.animate({ scrollTop: offset }, 200, () => dispatch(scrolledToPickedCategory(pickedCategory)));
    }
  }
};

export const checkDevice = () => dispatch => {
  const isIphone = is.iphone();
  const isIpad = is.ipad();
  const isIos = is.ios();
  const isAndroid = is.android();
  const isSafari = is.safari();
  const mobile = is.mobile();
  const tablet = is.tablet();

  const handheld = mobile || tablet;
  const standalone = isIos ? window.navigator.standalone === true : isRunningStandalone();
  const device = isIphone ? 'iPhone' : isIpad ? 'iPad' : 'device';
  const showOnIos = isIos === true && isSafari === true;
  const platform = isIos ? 'ios' : 'android';
  const showOnMobile = standalone === false && (isAndroid === true || showOnIos);

  dispatch(
    setDeviceValues({
      [STATE.DEVICE]: device,
      [STATE.HANDHELD]: handheld,
      [STATE.STANDALONE]: standalone,
      [STATE.PLATFORM]: platform,
      [STATE.SHOW_ON_MOBILE]: showOnMobile
    })
  );
};

export const actions = {
  checkDevice,
  setCurrentScrolledCategory,
  selectCategory,
  openDrawer,
  toggleDrawer,
  setScrollMarksCategory,
  scrolledToPickedCategory,
  setScrollerDom,
  setCategoryDom,
  setFooterStatus,
  openFooter,
  closeDrawer,
  closeFooter,
  openFooterCloseDrawer
};

export default handleActions(
  {
    ...setProperty(ACTIONS.SET_CURRENT_CATEGORY, STATE.SCROLLED_CATEGORY),
    ...setProperty(ACTIONS.SET_FOOTER_STATUS, STATE.FOOTER_OPENED),
    ...setProperty(ACTIONS.OPEN_FOOTER, STATE.FOOTER_OPENED, true),
    ...setProperty(ACTIONS.CLOSE_FOOTER, STATE.FOOTER_OPENED, false),
    ...setProperty(ACTIONS.SELECT_CATEGORY, STATE.PICKED_CATEGORY),
    ...setProperty(ACTIONS.OPEN_DRAWER, STATE.DRAWER_IS_OPEN),
    ...setProperty(ACTIONS.CLOSE_DRAWER, STATE.DRAWER_IS_OPEN, false),
    ...toggleBoolean(ACTIONS.TOGGLE_DRAWER, STATE.DRAWER_IS_OPEN),
    ...setProperty(ACTIONS.SET_SCROLL_MARKS_CATEGORY, STATE.SCROLL_MARKS_CATEGORY),
    ...setJqueryElement(ACTIONS.SET_SCROLLER_DOM, STATE.SCROLLER_DOM, false),
    ...setJqueryElement(ACTIONS.SET_CATEGORY_DOM, STATE.CATEGORIES_DOM, false),
    [ACTIONS.SET_DEVICE_VALUES]: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    [ACTIONS.SET_CURRENT_CATEGORY]: (state, action) => {
      if (state[STATE.SCROLLED_CATEGORY] !== action.payload) {
        return {
          ...state,
          [STATE.SCROLLED_CATEGORY]: action.payload
        };
      }
      return state;
    },
    [ACTIONS.SELECT_CATEGORY]: (state, action) => {
      return state[STATE.PICKED_CATEGORY] === -1
        ? {
            ...state,
            [STATE.DRAWER_IS_OPEN]: false,
            [STATE.SCROLL_MARKS_CATEGORY]: false,
            [STATE.PICKED_CATEGORY]: action.payload
          }
        : state;
    },
    [ACTIONS.SCROLLED_TO_PICKED_CATEGORY]: (state, action) => {
      return {
        ...state,
        [STATE.SCROLL_MARKS_CATEGORY]: true,
        [STATE.SCROLLED_CATEGORY]: action.payload,
        [STATE.PICKED_CATEGORY]: -1
      };
    }
  },
  {
    [STATE.SCROLLED_CATEGORY]: 1,
    [STATE.DRAWER_IS_OPEN]: false,
    [STATE.PICKED_CATEGORY]: -1,
    [STATE.SCROLL_MARKS_CATEGORY]: true,
    [STATE.SEARCHBAR_OPENED]: false,
    [STATE.FOOTER_OPENED]: false
  }
);
