import { translateAndScale } from 'utils/project';

export default {
  '0%': {
    opacity: 1,
    ...translateAndScale(0, 0, 1)
  },
  '100%': {
    opacity: 0,
    ...translateAndScale(0, 300, 0.9)
  }
};
