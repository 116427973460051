export const STATE = {
  HEADER_STATUS: 'headerStatus',
  CONTENT_STATUS: 'contentStatus'
};

export const ANIMATION_STATUS = {
  STARTED: 'STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  ENDED: 'ENDED'
};

export const ACTIONS = {
  SET_ANIMATION_STATE: 'SET_ANIMATION_STATE'
};
