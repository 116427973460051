export default [
  {
    name: 'Red Hot Chili Peppers',
    songs: [
      'By The Way',
      'Universally Speaking',
      'This Is The Place',
      'Dosed',
      `Don't Forget Me`,
      'The Zephyr Song',
      `Can't Stop`,
      'I Could Die For You',
      'Midnight',
      'Throw Away Your Television',
      'Cabron',
      'Tear',
      'On Mercury',
      'Minor Thing',
      'Warm Tape',
      'Venice Queen'
    ]
  },
  {
    name: 'Rolling Stones',
    songs: [
      'Gimme Shelter',
      'Love In Vain',
      'Country Honk',
      'Live With Me',
      'Let It Bleed',
      'Midnight Rambler',
      'You Got The Silver',
      'Monkey Man',
      `You Can't Always Get What You Want`
    ]
  },
  {
    name: 'Foo Fighters',
    songs: [
      'Bridge Burning',
      'Rope',
      'Dear Rosemary',
      'White Limo',
      'Arlandria',
      'These Days',
      'Back & Forth',
      'A Matter Of Time',
      'Miss The Misery',
      'I Should Have Known',
      'Walk'
    ]
  }
];
