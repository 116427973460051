import classnames from 'classnames';
import { css } from 'aphrodite';
import _ from 'lodash';
import $ from 'jquery';

export const cx = (...args) => ({
  className: classnames(args)
});

export const cxs = (...args) => ({
  className: classnames(_.map(args, arg => css(arg)))
});

export const animation = (
  keyframes,
  duration = 1,
  delay = 0,
  direction = 'normal',
  fillMode = 'none',
  iterationCount = 1
) => ({
  animationName: keyframes,
  animationDuration: `${duration}s`,
  animationDelay: `${delay}s`,
  animationDirection: direction,
  animationFillMode: fillMode,
  animationIterationCount: iterationCount
});

export const zeroOrPx = x => (x === 0 ? 0 : `${x}px`);
export const translate3DStr = (x = 0, y = 0, z = 0) =>
  `translate3d(${zeroOrPx(x)}, ${zeroOrPx(y)}, ${zeroOrPx(z)})`;

export const scaleStr = (x = 0) => `scale(${x})`;

export const translate3d = (x = 0, y = 0, z = 0) => ({
  transform: translate3DStr(x, y, z)
});

export const scale = (x = 0) => ({
  transform: scaleStr(x)
});

export const translateAndScaleZ = (x = 0, y = 0, z = 0, s = 0) => ({
  transform: `${translate3DStr(x, y, z)} ${scaleStr(s)}`
});

export const translateAndScale = (x = 0, y = 0, s = 0) => ({
  transform: `${translate3DStr(x, y, 0)} ${scaleStr(s)}`
});

export const translateX = (x = 0) => ({
  transform: `${translate3DStr(x, 0, 0)}`
});

export const translateY = (y = 0) => ({
  transform: `${translate3DStr(0, y, 0)}`
});

export const animateEqual = (properties, ...values) => {
  const percentIncrease = 100 / (values.length - 1);
  let result = {};
  let percent = 0;

  _.each(values, value => {
    let styles = {};
    _.each(value, (n, i) => {
      const prop = properties[i];
      styles = _.extend(styles, _.isFunction(prop) ? prop.apply(this, [n]) : { [prop]: n });
    });
    result = _.extend(result, { [`${percent}%`]: styles });
    percent += percentIncrease;
  });
  return result;
};

export const sequence = b =>
  _.every(b, (a, i) => !(a.call ? a() : setTimeout(() => sequence(b.slice(++i)), a)));
// export const sequence = a => a.reduce((t, s) => s.call ? setTimeout(s, t) && t : t + s, 0);

export const $ref = (t, name) => ({
  ref: ref => {
    t[name] = t[name] === undefined && ref !== null ? $(ref) : t[name];
  }
});
