import { scale } from 'utils/project';

export default {
  '0%': {
    ...scale(0.1),
    opacity: '0'
  },
  '30%': {
    ...scale(0.1),
    opacity: '0'
  },
  '100%': {
    ...scale(1),
    opacity: '1'
  }
};
