const DEBUG_MODE = ['localhost:3000', 'ok-google.surge.sh'].indexOf(window.location.host) !== -1;

const CONSTANTS = {
  DEV_TOOLS_ENABLED: false,
  DEBUG_MODE,
  DELAY_REQUESTS: true,
  API_URL: '/api',
  SHOW_ANIMATION: DEBUG_MODE === false,
  ANALYTICS_ID: 'UA-79646610-1'
};

const DOM_CONSTANTS = {
  PHRASES_IN_CATEGORY: 'phrases-in-category',
  SCROLLER: 'scroller'
};

export { DOM_CONSTANTS };

export default CONSTANTS;
