import React, { Component, PropTypes } from 'react';

class Icon extends Component {
  static propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { name, className, style } = this.props;

    return <span onClick={this.props.onClick} className={`icon-${name} ${className}`} style={style} />;
  }
}

export default Icon;
