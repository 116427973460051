export default [
  'Michael Jackson',
  'Lady Gaga',
  'Justin Bieber',
  'Jennifer Aniston',
  'Eminem',
  'Johnny Depp',
  'Will Smith',
  'Katy Perry',
  'Beyonce',
  'Leonardo DiCaprio',
  'Ronaldo',
  'Lionel Messi',
  'Marilyn Manson',
  'Shakira',
  'Roger Federer',
  'Madonna',
  'Avril Lavigne',
  'Rihanna',
  'Angelina Jolie',
  'Brad Pitt',
  'Taylor Swift',
  'Britney Spears',
  'Adele',
  'Robert Pattinson',
  'Justin Timberlake',
  'Selena Gomez',
  'Barack Obama',
  'Bob Marley',
  'Miley Cyrus',
  'Cameron Diaz',
  'Akon',
  'Eddie Murphy',
  'Megan Fox',
  'Christina Aguilera',
  'Michael Jordan',
  'Pink',
  'Marilyn Monroe',
  'Nicki Minaj',
  'Steve Jobs',
  'Nicolas Cage',
  'Drake',
  'Kristen Stewart',
  'Dr House',
  'Carmen Electra',
  'Jason Statham',
  'Kesha',
  'Jack Nicholson',
  'Tom Cruise',
  'Tom Hanks',
  'Jennifer Lopez',
  'Kim Kardashian',
  'Hugh Grant',
  'Daniel Radcliffe',
  'Chris Brown',
  'Muhammad Ali',
  'Whoopi Goldberg',
  'Nicole Kidman',
  'David Beckham',
  'Adam Sandler',
  'Gwen Stefani',
  'Michelle Obama',
  'Lil Wayne',
  'Julia Roberts',
  'Bill Gates',
  'Ben Stiller',
  'Steven Spielberg',
  'Jessica Alba',
  'Jay Z',
  'Alicia Keys',
  'Oprah Winfrey',
  'Halle Berry',
  'Charlie Sheen',
  'Scarlett Johansson',
  'David Duchovny',
  'Ashton Kutcher',
  'Sandra Bullock',
  'Jennifer Love Hewitt',
  'Paris Hilton',
  'Uma Thurman',
  'George Clooney',
  'Drew Barrymore',
  'Tyler Perry',
  'Barbra Streisand',
  'Mike Tyson',
  'Reese Witherspoon',
  'Sarah Jessica Parker',
  'Stephenie Meyer',
  'Demi Moore',
  'Alyssa Milano',
  'Katherine Heigl',
  'Cindy Crawford',
  'Catherine Zeta Jones',
  'Tiger Woods',
  'Robert De Niro',
  'Kelli Williams',
  'James Cameron',
  'Michael Douglas',
  'Kevin Costner',
  'Woody Allen',
  'Alec Baldwin'
];
