import React from 'react';
import mixins from 'stylz';
import queries from 'styles/queries';
import { StyleSheet } from 'aphrodite';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';


const styles = StyleSheet.create({
  Ad: {
    ...mixins.padding.horizontal(40),
    ...mixins.padding.vertical(15),
    marginBottom: 15,
    position: 'relative',
    color: '#1b1b1b',
    textDecoration: 'none',
    [queries.mobileLayout]: {
      ...mixins.padding.both(8, 15),
      fontSize: 15
    },
    fontSize: 16,
    borderRadius: 5,
    minHeight: 55,
    userSelect: 'none',
    flexWrap: 'wrap',
    lineHeight: '25px',
    backgroundColor: 'white',
    boxShadow: '#c7c7c7 0px 1px 1px',
    transition: 'all 100ms linear',
    width: '100%',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#f7f7f7',
      transform: 'scale(1.01)'
    }
  },
  logo: {
    width: 40,
    marginRight: 10
  },
  nameAndLogo: {
    marginBottom: 10
  },
  name: {
    fontWeight: 500,
    marginRight: 10,
    fontSize: 18
  },
  text: {},
  ad: {
    backgroundColor: '#ffd086',
    borderRadius: 3,
    fontSize: 11,
    lineHeight: '11px',
    position: 'absolute',
    top: 10,
    right: 10,
    padding: '3px 5px'
  }
});

function Ad({logo, url, name, height = 40, description}) {
  console.log('height', height);
  return (
    <a href={url} target="_blank" {...cxs(styles.Ad, flex.vertical, flex.centerVerticalV)}>
      <div {...cxs(styles.nameAndLogo, flex.horizontal, flex.centerHorizontalV)}>
        <img {...cxs(styles.logo)} style={{
          height
        }} src={logo} />
        <div {...cxs(styles.name)}>{name}</div>
      </div>
      <div {...cxs(styles.ad, flex.horizontal, flex.centerHorizontalV)}>ad</div>
      <div>{description}</div>
    </a>
  );
}

export default Ad;
