export default [
  {
    text: `What's the %company% stock price?`,
    category: 4
  },
  {
    text: `What's %company% trending at?`,
    category: 4
  }
];
