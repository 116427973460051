export default [
  {
    text: `What's %number% %measure1% in %measure2% ?`,
    process: 'measure',
    category: 5
  },
  {
    text: `Convert %number% %measure1% to %measure2% ?`,
    process: 'measure',
    category: 5
  },
  {
    text: `What's %number% %currencySymbol% to %currencySymbol% ?`,
    process: 'currencies',
    category: 5
  },
  {
    text: `What's the tip for $number(20,200)$ dollars?`,
    category: 5
  }
];
