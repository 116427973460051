export default {
  when: {
    text: 'when i get',
    locations: {
      gym: 'at the',
      work: 'to'
    }
  },
  nextTime: {
    text: `next time i'm at`,
    locations: {
      gym: 'the'
    }
  }
};
