export default [
  {
    text: 'Who is %person% ?',
    category: 1
  },
  {
    text: 'How old is %person% ?',
    category: 1
  },
  {
    text: 'Where was %person% born ?',
    category: 1
  },
  {
    text: 'Who is %person% married to?',
    category: 1
  },
  {
    text: `Who is %person% 's %relative% ?`,
    category: 1
  },
  {
    text: 'Who wrote %book% ?',
    category: 1
  },
  {
    text: 'Who invented the %invention% ?',
    category: 1
  }
];
