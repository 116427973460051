import mixins from 'stylz';
import { StyleSheet } from 'aphrodite';
import queries from 'styles/queries';
import sharedSizes from './shared-sizes';
import { animation } from 'utils/project';
import colors from 'styles/colors';
import flyInFromLeft from 'animations/fly-in-from-left';

/*
 * The search section needs padding and the phrases section needs margin.
 * This is the best way to make everything re-usable.
 * If something is changed in shared-sizes, it's reflected everywhere.
 */

const RightSideMixin = (property, equal) => {
  const { offset } = sharedSizes.rightSide;

  return {
    ...(equal !== true && mixins[property].horizontal(offset.bigSize.left, offset.bigSize.right)),
    ...(equal == true && mixins[property].horizontal(offset.equalSize)),
    [queries.mobileLayout]: {
      ...mixins[property].horizontal(offset.smallSize)
    }
  };
};

const styles = StyleSheet.create({
  Wrapper: {
    maxWidth: 960,
    width: '100%',
    margin: 'auto',
    ...mixins.padding.horizontal(sharedSizes.Wrapper.padding.default),
    [queries.smallerThanTabletL]: {
      ...mixins.padding.horizontal(sharedSizes.Wrapper.padding.smallerThanTabletL)
    },
    [queries.smallerThanPhablet]: {
      ...mixins.padding.horizontal(sharedSizes.Wrapper.padding.smallerThanPhablet)
    },
    [queries.smallerThanPhone]: {
      ...mixins.padding.horizontal(sharedSizes.Wrapper.padding.smallerThanPhone)
    }
  },
  LeftSide: {
    ...mixins.animate(),
    width: sharedSizes.leftSideWidth.bigSize,
    maxWidth: sharedSizes.leftSideWidth.bigSize,
    [queries.smallerThanTabletL]: {
      width: sharedSizes.leftSideWidth.smallSize
    },
    [queries.mobileLayout]: {
      minWidth: 0,
      width: 'auto'
    }
  },
  RightSidePadding: {
    ...RightSideMixin('padding'),
    ...mixins.animate()
  },
  RightSideEqualPadding: {
    ...RightSideMixin('padding', true)
  },
  RightSideMargin: {
    ...RightSideMixin('margin')
  },
  Card: {
    ...mixins.padding.both(25),
    ...mixins.cardShadow,
    ...animation(flyInFromLeft, 0.5),
    height: '100%',
    width: '50%',
    backgroundColor: colors.white,
    transformOrigin: 'top left',

    [queries.oneColumnCards]: {
      width: '100%',
      height: 'auto'
    },
    [queries.smallerTabletHeight]: {
      ...mixins.padding.both(15)
    }
  },
  hidden: {
    opacity: 0,
    ...mixins.animate(),
    cursor: 'pointer',
    visibility: 'hidden'
  }
});

export default styles;
