import React, { Component, PropTypes } from 'react';
import { css } from 'aphrodite';
import _ from 'lodash';
import mixins from 'stylz';

import twizzy from 'img/twizzy.png';
import ship from 'img/ship.png';
import sizzy from 'img/sizzy.png';

import { DOM_CONSTANTS } from 'config/constants';

//styles
import styles, { dynamicStyles } from './styles';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';
import sharedStyles from 'styles/shared-styles';

//components
import Phrase from 'components/Phrase';
import NoPhrasesFound from 'components/NoPhrasesFound';
import CategoryHeader from 'components/CategoryHeader';
import Ad from 'components/Ad';

class Phrases extends Component {
  static propTypes = {
    phrasesByCategory: PropTypes.array,
    searchResults: PropTypes.object,
    setScrollerDom: PropTypes.func,
    setCategoryDom: PropTypes.func,
    filterTextIsNotEmpty: PropTypes.bool,
    handheld: PropTypes.bool
  };

  componentDidMount() {
    const { setCategoryDom, setScrollerDom } = this.props;
    setCategoryDom(`.${DOM_CONSTANTS.PHRASES_IN_CATEGORY}`);
    setScrollerDom(`.${DOM_CONSTANTS.SCROLLER}`);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.searchResults !== nextProps.searchResults;
  }

  render() {
    const { phrasesByCategory, searchResults, filterTextIsNotEmpty, handheld } = this.props;

    return (
      <div
        {...cx(
          css(styles.Phrases),
          css(sharedStyles.RightSidePadding),
          filterTextIsNotEmpty && css(sharedStyles.RightSideEqualPadding),
          DOM_CONSTANTS.SCROLLER
        )}
      >
        {!filterTextIsNotEmpty && (
          <Ad
            name="Sizzy"
            logo={sizzy}
            url="https://sizzy.co?ref=okgoogle"
            description="The browser for designers and developers. Absolute game-changer for working on responsive design."
          />
        )}

        {searchResults.foundPhrasesLength === 0 && <NoPhrasesFound />}

        {_.map(phrasesByCategory, (phraseCategory, index) => {
          const phrasesForCategory = searchResults.filteredPhrases[phraseCategory.id].phrases;
          const showCategory = phrasesForCategory.length !== 0;
          let evenCounter = 0;

          return (
            <div
              key={index}
              data-category-id={phraseCategory.id}
              style={dynamicStyles.phraseCategory(showCategory)}
              {...cx(css(styles.phraseCategory), DOM_CONSTANTS.PHRASES_IN_CATEGORY)}
            >
              {index === 1 && !filterTextIsNotEmpty && (
                <Ad
                  name="Zero To Shipped"
                  logo={ship}
                  height="150px !important"
                  url="https://zerotoshipped.com"
                  description="The best interactive course on fullstack web development"
                />
              )}
              <CategoryHeader category={phraseCategory} />
              {/* Phrases */}
              <div {...cxs(styles.phrasesGroup, flex.vertical)}>
                {_.map(phraseCategory.phrases, (phrase, index) => {
                  //check if phrasesForCategory contains phrase
                  const show = phrasesForCategory.indexOf(phrase.id) !== -1;
                  if (show) evenCounter++;
                  const addEvenStyle = evenCounter % 2 === 0 && show === true;

                  return (
                    <span
                      {...cxs(styles.phraseWrap, addEvenStyle && styles.phraseWrapEven)}
                      style={mixins.show(show)}
                      key={phrase.id}
                    >
                      <Phrase phrase={phrase} key={phrase.id} handheld={handheld} />
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Phrases;
