import _ from 'lodash';
import { StyleSheet } from 'aphrodite';
import mixins from 'stylz';
import queries from 'styles/queries';
import socialNetworks from 'config/social-networks';
import color from 'color';

export const sizes = {};

let socialNetworksWithHover = {};

_.each(socialNetworks, n => {
  socialNetworksWithHover = {
    ...socialNetworksWithHover,
    [`SocialNetwork${n.name}`]: {
      backgroundColor: color(n.backgroundColor)
        .desaturate(0.6)
        .hexString(),
      opacity: 0.9,
      color: n.color,
      ':hover': {
        backgroundColor: n.backgroundColor,
        opacity: 1
      }
    }
  };
});

export const style = StyleSheet.create({
  SocialNetworks: {},
  socialIcon: {
    fontSize: 20
  },
  socialButton: {
    flex: 1,
    height: 50,
    cursor: 'pointer',
    ...mixins.animate(150),
    [queries.smallerPhone]: {
      minWidth: 60
    }
  },
  ...socialNetworksWithHover
});

export default style;
