import colors from 'styles/colors';

export const socialNetworkColors = {
  medium: '#02B875',
  github: 'rgb(74, 74, 74)',
  youtube: 'rgb(229, 57, 53)',
  twitter: '#55ACEE',
  linkedin: '#1C86BD'
};

export default [
  {
    name: 'Medium',
    icon: 'medium',
    url: 'https://medium.com/@kitze',
    backgroundColor: socialNetworkColors.medium,
    color: colors.white
  },
  {
    name: 'GitHub',
    icon: 'github',
    url: 'https://github.com/kitze',
    backgroundColor: socialNetworkColors.github,
    color: colors.white
  },
  {
    name: 'Twitter',
    icon: 'twitter',
    url: 'https://twitter.com/thekitze',
    backgroundColor: socialNetworkColors.twitter,
    color: colors.white
  },
  {
    name: 'YouTube',
    icon: 'youtube',
    url: 'https://youtube.com/techmuggles',
    backgroundColor: colors.red,
    color: colors.white
  }
];
