import _ from 'lodash';

import { createSelector } from 'reselect';

const pickPhrases = state => state.entities.phrases;
const pickCategories = state => state.entities.categories;
const pickFilterText = state => state.form && state.form.searchForm && state.form.searchForm.search.value;

export const getPhrasesByCategory = createSelector([pickPhrases, pickCategories], (phrases, categories) => {
  const groupedPhrasesByCategory = _.groupBy(phrases, 'category');

  return _.map(groupedPhrasesByCategory, (group, key) => ({
    ...categories[key],
    phrases: group
  }));
});

export const getSearchResults = createSelector(
  [getPhrasesByCategory, pickFilterText],
  (phrasesByCategory, filterText) => {
    var filterTextExists = filterText !== undefined && filterText.trim() !== '';

    const mapped = {};
    let totalPhrasesLength = 0;
    let foundPhrasesLength = 0;

    _.each(phrasesByCategory, category => {
      totalPhrasesLength += category.phrases.length;

      const phraseMap = _.map(category.phrases, phrase => {
        return phrase.text.toLowerCase().indexOf(filterText ? filterText.toLowerCase() : undefined) !== -1 ||
          filterTextExists === false
          ? phrase.id
          : undefined;
      });

      const filteredPhrases = _.compact(phraseMap);

      foundPhrasesLength += filteredPhrases.length;

      mapped[category.id] = {
        id: category.id,
        phrases: filteredPhrases
      };
    });

    return {
      filteredPhrases: mapped,
      totalPhrasesLength,
      foundPhrasesLength
    };
  }
);
