import { helpers } from 'stylz/src/mixins/responsive';
import devices from 'stylz/src/devices';

const { mobilePrefix, maxWidth, maxHeight } = helpers;

const queries = {
  smallerThanTabletL: `${mobilePrefix} ${maxWidth(devices.tabletL.width)}`,
  oneColumnCards: `${mobilePrefix} ${maxWidth(820)}, screen ${maxHeight(680)}`,
  mobileLayout: `${mobilePrefix} ${maxWidth(devices.tabletL.width)}`,
  smallerThanPhone: `${mobilePrefix} ${maxWidth(devices.phone.width)}`,
  smallerPhone: `${mobilePrefix} ${maxWidth(devices.phoneM.width)}`,
  smallerThanPhablet: `${mobilePrefix} ${maxWidth(devices.phablet.width)}`,
  smallerTabletHeight: `${mobilePrefix} ${maxHeight(700)}`
};

export default queries;
