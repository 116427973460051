export default [
  {
    text: `How is %sportsTeam% doing?`,
    category: 19
  },
  {
    text: `Results from %sportsTeam% last game`,
    category: 19
  },
  {
    text: `When is the next %sportsTeam% game`,
    category: 19
  },
  {
    text: 'Did %sportsTeam% win the last game?',
    category: 19
  }
];
