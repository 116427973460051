export const STATE = {
  PLATFORM: 'platform',
  SCROLLED_CATEGORY: 'scrolledCategory',
  PICKED_CATEGORY: 'pickedCategory',
  DRAWER_IS_OPEN: 'drawerIsOpen',
  SCROLL_MARKS_CATEGORY: 'scrollMarksCategory',
  DEVICE: 'device',
  HANDHELD: 'handheld',
  STANDALONE: 'standalone',
  PLATFORM: 'platform',
  SHOW_ON_MOBILE: 'showOnMobile',
  SCROLLER_DOM: 'scrollerDom',
  CATEGORIES_DOM: 'categoriesDom',
  FOOTER_OPENED: 'footerOpened'
};

export const ACTIONS = {
  SET_DEVICE_VALUES: 'SET_DEVICE_VALUES',
  SET_FOOTER_STATUS: 'SET_FOOTER_STATUS',
  OPEN_FOOTER: 'OPEN_FOOTER',
  CLOSE_FOOTER: 'CLOSE_FOOTER',
  SET_CURRENT_CATEGORY: 'SET_CURRENT_CATEGORY',
  SELECT_CATEGORY: 'SELECT_CATEGORY',
  TOGGLE_DRAWER: 'TOGGLE_DRAWER',
  OPEN_DRAWER: 'OPEN_DRAWER',
  CLOSE_DRAWER: 'CLOSE_DRAWER',
  SET_SCROLL_MARKS_CATEGORY: 'SET_SCROLL_MARKS_CATEGORY',
  SCROLLED_TO_PICKED_CATEGORY: 'SCROLLED_TO_PICKED_CATEGORY',
  SET_CATEGORY_DOM: 'SET_CATEGORY_DOM',
  SET_SCROLLER_DOM: 'SET_SCROLLER_DOM'
};
