import axios from 'axios';
import $ from 'jquery';

import { createAction, handleActions } from 'redux-actions';
import CONSTANTS from '../../../config/constants';

import { async, setProperty } from './../../redux-helpers';
import { STATE, ACTIONS } from './user.constants';

// ------------------------------------
// Actions
// ------------------------------------

export const setUser = createAction(ACTIONS.SET_USER);
export const loadingLogin = createAction(ACTIONS.LOADING_LOGIN);
export const setError = createAction(ACTIONS.SET_ERROR);
export const logout = () => async(ACTIONS.LOGOUT, `${CONSTANTS.API_URL}/users/logout`, 'post');

export const login = params => dispatch => {
  dispatch(loadingLogin(true));
  axios
    .post(`${CONSTANTS.API_URL}/users/auth`, params)
    .then(result => {
      dispatch(loadingLogin(false));
      dispatch(setError(false));
      dispatch(setUser(result.data));
    })
    .catch(error => {
      let errorMessage;

      switch (error.status) {
        case 400: {
          errorMessage = 'Invalid credentials. Please try again!';
          break;
        }
        case 500: {
          errorMessage = 'Internal server error!';
          break;
        }
        default: {
          errorMessage = 'An error occured.';
        }
      }

      dispatch(loadingLogin(false));
      dispatch(setError(errorMessage));
    });
};

const setUserWithTimeout = (value, dispatch) => {
  setTimeout(() => {
    dispatch(setUser(value));
  }, 0);
};

export const checkAuth = () => dispatch => {
  $.get(`${CONSTANTS.API_URL}/users/get-current`)
    .done(result => setUserWithTimeout(result, dispatch))
    .fail(err => setUserWithTimeout(null, dispatch));
};

export const actions = {
  login,
  logout,
  checkAuth
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    ...setProperty(ACTIONS.SET_USER, STATE.CURRENT),
    ...setProperty(ACTIONS.LOGOUT_INIT, STATE.CURRENT, false),
    ...setProperty(ACTIONS.LOADING_LOGIN, STATE.LOADING),
    ...setProperty(ACTIONS.SET_ERROR, STATE.ERROR)
  },
  {}
);
