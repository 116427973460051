export default [
  {
    text: 'Add a reminder',
    category: 17
  },
  {
    text: 'Remind me to %keepNote% at $time()$',
    category: 17
  },
  {
    text: `Remind me to %reminder% %whenAtLocation% %location%`,
    process: 'locationReminder',
    category: 17
  },
  {
    text: 'Remind me to buy %shoppingListItem% at %shoppingPlace%',
    category: 17
  },
  {
    text: 'Remind me to buy %shoppingListItem% at %shoppingCategory%',
    process: 'shoppingCategory',
    category: 17
  },
  {
    text: `Remind me to %recurringReminder% every $dayOfWeek()$`,
    category: 17
  }
];
