export default [
  {
    text: 'Go to %site%',
    category: 21
  },
  {
    text: '%goToSite% %website%',
    category: 21
  }
];
