import mixins from 'stylz';
import { StyleSheet } from 'aphrodite';
import queries from 'styles/queries';
import colors from 'styles/colors';
import sharedSizes from 'styles/shared-sizes';
import is from 'plugins/is';

const sizes = {};

const dynamicStyles = {
  Sidebar: (collapsed, hovered, mobile) => ({
    ...(mobile === false && { overflowY: 'hidden' }),
    ...(mobile === false && hovered && { overflowY: 'scroll' }),
    ...(mobile === true && { height: '100%' }),
    flex: collapsed === true ? 0 : 1
  })
};

const style = StyleSheet.create({
  Sidebar: {
    [queries.mobileLayout]: {
      display: 'none'
    }
  },
  SidebarMobile: {
    [queries.mobileLayout]: {
      display: 'flex'
    }
  },
  label: {
    fontSize: 23,
    color: '#8B8B8B',
    borderBottom: '1px solid #E8E8E8',
    paddingBottom: 25,
    marginBottom: 25,
    width: 250
  }
});

const notIos = is.ios() === false;

export const categoryStyle = StyleSheet.create({
  wrap: {
    minHeight: 40,
    borderRadius: 5,
    whiteSpace: 'nowrap',
    textTransform: 'initial',
    textAlign: 'inherit',
    ...mixins.padding.horizontal(10),
    color: '#404040',
    fontSize: 16,
    cursor: 'pointer',
    ...mixins.animate(),
    ...(notIos && {
      ':hover': {
        backgroundColor: colors.lightGray
      }
    })
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    color: 'rgb(107, 107, 107)',
    minWidth: 20,
    textAlign: 'center'
  },
  label: {
    fontSize: 16,
    color: '#8B8B8B',
    fontWeight: 300
  }
});

export { dynamicStyles };
export default style;
