export default [
  {
    text: 'Map of $country()$',
    category: 18
  },
  {
    text: 'Show me nearest restaurants on map',
    category: 18
  },
  {
    text: 'Navigate to $city()$ by car',
    category: 18
  },
  {
    text: `Navigate to $contactOrRelative()$ 's place`,
    category: 18
  },
  {
    text: `How far is $city()$ from $city()$ ?`,
    category: 18
  },
  {
    text: 'Walking directions to %popularPlace%',
    category: 18
  },
  {
    text: 'What are some attractions around here?',
    category: 18
  },
  {
    text: 'Show me popular museums in %continent%',
    category: 18
  },
  {
    text: 'Where is %popularPlace% ?',
    category: 18
  },
  {
    text: 'Is %openClosePlace% open now?',
    category: 18
  },
  {
    text: 'When does %openClosePlace% close?',
    category: 18
  },
  {
    text: 'Is %openClosePlace% open on $dayOfWeek()$ %timeOfDay% ?',
    category: 18
  },
  {
    text: 'Distance from here to %popularPlace%',
    category: 18
  },
  {
    text: 'How far away is $country()$ ?',
    category: 18
  }
];
