import { translateAndScale } from 'utils/project';

export default {
  '0%': {
    opacity: '0',
    ...translateAndScale(0, 600, -0.01)
  },
  '50%': {
    opacity: '1',
    ...translateAndScale(0, 300, 0.2)
  },
  '100%': {
    opacity: '1',
    ...translateAndScale(0, 0, 1)
  }
};
