import { combineReducers } from 'redux';

//lib
import { reducer as formReducer } from 'redux-form';

//usr
import user from 'redux/modules/user/user.redux.js';
import entities from 'redux/modules/entities/entities.redux.js';
import ui from 'redux/modules/ui/ui.redux';
import app from 'redux/modules/app/app.redux';
import search from 'redux/modules/search/search.redux';
import animations from 'redux/modules/animations/animations.redux';
import { STATE } from 'redux/rootReducer.constants';

export default combineReducers({
  [STATE.FORM]: formReducer,
  [STATE.ENTITIES]: entities,
  [STATE.UI]: ui,
  [STATE.APP]: app,
  [STATE.USER]: user,
  [STATE.SEARCH]: search,
  [STATE.ANIMATIONS]: animations
});
