import shoppingListItems from './shopping-list-items';

export default [
  {
    name: 'pharmacy',
    items: ['pills', 'vitamins', 'diapers', 'wet wipes']
  },
  {
    name: 'supermarket',
    items: shoppingListItems
  }
];
