import React, { Component } from 'react';
import _ from 'lodash';

//reselect
import { getPhrasesByCategory, getSearchResults } from 'redux/modules/phrases/phrases.reselect';

//actions
import { actions as searchActions } from 'redux/modules/search/search.redux';
import { actions as animationActions } from 'redux/modules/animations/animations.redux';
import { actions as appActions } from 'redux/modules/app/app.redux';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//constants
import { STATE as ROOT_STATE } from 'redux/rootReducer.constants';
import { STATE as ANIMATIONS_STATE } from 'redux/modules/animations/animations.constants';
import { STATE as ENTITIES_STATE } from 'redux/modules/entities/entities.constants';
import { STATE as APP_STATE } from 'redux/modules/app/app.constants';
import { STATE as SEARCH_STATE } from 'redux/modules/search/search.constants';

const CONSTANTS = {
  PHRASES_BY_CATEGORY: 'phrasesByCategory',
  FILTER_TEXT: 'filterText',
  ACTIONS: 'actions',
  SEARCH_RESULTS: 'searchResults'
};

import Home from './index';

class Container extends Component {
  render() {
    const props = _.pick(this.props, [
      CONSTANTS.ACTIONS,
      CONSTANTS.PHRASES_BY_CATEGORY,
      CONSTANTS.FILTER_TEXT,
      ENTITIES_STATE.CATEGORIES,
      APP_STATE.SCROLLED_CATEGORY,
      APP_STATE.PICKED_CATEGORY,
      APP_STATE.SCROLL_MARKS_CATEGORY,
      APP_STATE.DRAWER_IS_OPEN,
      APP_STATE.HANDHELD,
      APP_STATE.FOOTER_OPENED,
      SEARCH_STATE.SEARCHBAR_OPENED,
      CONSTANTS.SEARCH_RESULTS
    ]);
    return <Home {...props} />;
  }
}

export default connect(
  state => {
    const searchResults = getSearchResults(state);

    return {
      [CONSTANTS.PHRASES_BY_CATEGORY]: getPhrasesByCategory(state),
      [CONSTANTS.SEARCH_RESULTS]: searchResults,
      [ENTITIES_STATE.CATEGORIES]: state[ROOT_STATE.ENTITIES][ENTITIES_STATE.CATEGORIES],
      [SEARCH_STATE.SEARCHBAR_OPENED]: state[ROOT_STATE.SEARCH][SEARCH_STATE.SEARCHBAR_OPENED],
      [APP_STATE.SCROLLED_CATEGORY]: state[ROOT_STATE.APP][APP_STATE.SCROLLED_CATEGORY],
      [APP_STATE.FOOTER_OPENED]: state[ROOT_STATE.APP][APP_STATE.FOOTER_OPENED],
      [APP_STATE.DRAWER_IS_OPEN]: state[ROOT_STATE.APP][APP_STATE.DRAWER_IS_OPEN],
      [APP_STATE.PICKED_CATEGORY]: state[ROOT_STATE.APP][APP_STATE.PICKED_CATEGORY],
      [APP_STATE.HANDHELD]: state[ROOT_STATE.APP][APP_STATE.HANDHELD],
      [APP_STATE.SCROLL_MARKS_CATEGORY]: state[ROOT_STATE.APP][APP_STATE.SCROLL_MARKS_CATEGORY],
      [CONSTANTS.FILTER_TEXT]: _.get(state[ROOT_STATE.FORM], 'searchForm.search.value')
    };
  },
  dispatch => ({
    actions: bindActionCreators({ ...searchActions, ...animationActions, ...appActions }, dispatch)
  })
)(Container);
