import React, { Component } from 'react';
import _ from 'lodash';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as animationActions } from 'redux/modules/animations/animations.redux.js';
import { STATE as ROOT_STATE } from 'redux/rootReducer.constants';
import { STATE as ANIMATION_STATE } from 'redux/modules/animations/animations.constants';

const CONSTANTS = {
  ACTIONS: 'actions'
};

//components
import IntroAnimation from './index';

class Container extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = _.pick(this.props, [CONSTANTS.ACTIONS]);

    return <IntroAnimation {...props} />;
  }
}

export default connect(
  state => {
    return {};
  },
  dispatch => ({
    actions: bindActionCreators({ ...animationActions }, dispatch)
  })
)(Container);
