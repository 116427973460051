export default [
  {
    text: `Square root of $number()$ ?`,
    category: 6
  },
  {
    text: `What's $number()$ %mathOperation% $number()$ ?`,
    category: 6
  },
  {
    text: `What's $number(1,99)$ percent of $number()$ ?`,
    category: 6
  },
  {
    text: `What's $number(1,99)$ percent of $number()$ %mathOperation% $number()$ ? `,
    category: 6
  }
];
