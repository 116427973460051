import React, { Component, PropTypes } from 'react';
import { css } from 'aphrodite';

//styles
import styles from './styles';
import { cxs } from 'utils/project';
import flex from 'styles/flex';
import sharedStyles from 'styles/shared-styles';

//components
import Icon from 'components/Icon';

class Footer extends Component {
  static propTypes = {
    opened: PropTypes.bool,
    setFooterStatus: PropTypes.func
  };

  shouldComponentUpdate(nextProps) {
    return this.props.opened !== nextProps.opened;
  }

  render() {
    const { opened, setFooterStatus } = this.props;

    return (
      <div
        id="footer"
        onClick={() => setFooterStatus(!opened)}
        {...cxs(styles.Footer, opened === true && styles.FooterOpened, flex.horizontal)}
      >
        <div {...cxs(sharedStyles.Wrapper, styles.wrapper, flex.horizontal, flex.centerVertical)}>
          <div className={css(styles.text)}> made with</div>
          <div className={css(styles.love)}>
            <Icon name="heart" />
          </div>
          <div className={css(styles.text)}> by kitze</div>
        </div>
      </div>
    );
  }
}

export default Footer;
