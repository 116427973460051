import React, { Component, PropTypes } from 'react';
import _ from 'lodash';

//styles
import styles, { categoryStyle, dynamicStyles } from './styles';
import flex from 'styles/flex';
import { css } from 'aphrodite';
import { cx, cxs } from 'utils/project';
import sharedStyles from 'styles/shared-styles';

//external
import Icon from 'components/Icon';

class Sidebar extends Component {
  static propTypes = {
    mobile: PropTypes.bool,
    collapsed: PropTypes.bool,
    style: PropTypes.object,
    categories: PropTypes.object,
    selectCategory: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.collapsed !== nextProps.collapsed || this.state.hovered !== nextState.hovered;
  }

  _setHovered(hovered) {
    this.setState({ hovered });
  }

  _hover = () => {
    this.setState({ hovered: true });
  }

  _unhover = () => {
    this.setState({ hovered: false });
  }

  render() {
    const { categories, mobile, selectCategory, collapsed } = this.props;
    const { hovered } = this.state;

    return (
      <div
        onMouseEnter={this._hover}
        onMouseLeave={this._unhover}
        {...cxs(
          styles.Sidebar,
          flex.vertical,
          mobile === true && styles.SidebarMobile,
          sharedStyles.LeftSide
        )}
        style={dynamicStyles.Sidebar(collapsed, hovered, mobile)}
      >
        {_.map(categories, (category, index) => {
          return (
            <div
              key={index}
              {...cx(css(categoryStyle.wrap), css(flex.horizontal), css(flex.centerHorizontalV))}
              onClick={() => selectCategory(category.id)}
            >
              <Icon name={category.icon} {...cx(css(categoryStyle.icon))} />
              <div {...cx(css(categoryStyle.label))}>{category.name}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Sidebar;
