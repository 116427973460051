export default [
  {
    text: 'When was %movie% released?',
    category: 22
  },
  {
    text: 'Runtime of %movie% ?',
    category: 22
  },
  {
    text: 'Listen to TV',
    category: 22
  },
  {
    text: 'Who is the producer of %movie% ?',
    category: 22
  },
  {
    text: 'Who acted in %movie% ?',
    category: 22
  },
  {
    text: 'Best movies of $number(2000,2015)$',
    category: 22
  },
  {
    text: 'Best %genre% movies',
    category: 22
  },
  {
    text: `$genreCapitalized()$ movies of $number(2000,2015)$`,
    category: 22
  },
  {
    text: 'Oscar winners of $number(2000,2015)$',
    category: 22
  },
  {
    text: 'What are the best %actor% movies',
    category: 22
  },
  {
    text: 'What movies are playing $movieTime()$ ?',
    category: 22
  },
  {
    text: 'Where is %movie% playing?',
    category: 22
  }
];
