export default [
  {
    name: 'People & Relationships',
    icon: 'user',
    id: 1
  },
  {
    name: 'Time',
    icon: 'clock',
    id: 2
  },
  {
    name: 'Weather',
    icon: 'cloud-sun',
    id: 3
  },
  {
    name: 'Stocks',
    icon: 'chart-line',
    id: 4
  },
  {
    name: 'Conversion',
    icon: 'arrows-cw',
    id: 5
  },
  {
    name: 'Math',
    icon: 'calc',
    id: 6
  },
  {
    name: 'Device control',
    icon: 'cogs',
    id: 7
  },
  {
    name: 'Definitions',
    icon: 'book',
    id: 8
  },
  {
    name: 'Alarms',
    icon: 'wristwatch',
    id: 9
  },
  {
    name: 'Timer & Stopwatch',
    icon: 'hourglass',
    id: 25
  },
  {
    name: 'Calendar',
    icon: 'calendar',
    id: 10
  },
  {
    name: 'Gmail integration',
    icon: 'mail-alt',
    id: 11
  },
  {
    name: 'Google Keep & Notes',
    icon: 'doc-text',
    id: 12
  },
  {
    name: 'Contacts & Calls',
    icon: 'phone',
    id: 13
  },
  {
    name: 'Messaging',
    icon: 'comment',
    id: 14
  },
  {
    name: 'Social apps',
    icon: 'facebook',
    id: 15
  },
  {
    name: 'Translation',
    icon: 'language',
    id: 16
  },
  {
    name: 'Reminders',
    icon: 'bell-alt',
    id: 17
  },
  {
    name: 'Maps & Navigation',
    icon: 'location',
    id: 18
  },
  {
    name: 'Sports',
    icon: 'soccer-ball',
    id: 19
  },
  {
    name: 'Flight & Travel',
    icon: 'flight',
    id: 20
  },
  {
    name: 'Web Browsing',
    icon: 'globe',
    id: 21
  },
  {
    name: 'Movies & TV Shows',
    icon: 'video',
    id: 22
  },
  {
    name: 'Easter eggs',
    icon: 'emo-happy',
    id: 23
  },
  {
    name: 'Music',
    icon: 'music',
    id: 24
  }
];
