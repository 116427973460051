import _ from 'lodash';
import $ from 'jquery';
import React, { Component, PropTypes } from 'react';
import { sequence } from 'utils/project';
import microphoneImg from 'img/microphone.png';
import CONSTANTS from 'config/constants';

//styles
import { css } from 'aphrodite';
import styles, { dynamicStyles } from './styles';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';

class IntroAnimation extends Component {
  static propTypes = {
    setFooterStatus: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      moveCircleToMiddle: false,
      showGrayCircle: false,
      pulseGrayCircle: false,
      showMicrophone: false,
      moveCircleToTop: false,
      sentence: false,
      fadeOutText: false,
      moveCircleBackCenter: false,
      hideMicrophone: false,
      stickCircleToTop: false,
      fadeOut: false,
      hide: false
    };

    this.sentence = `Ok Google - can you show me a list of your commands?`;
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.stickCircleToTop === true && this.redBallOffset === undefined) {
      this.redBallOffset = this.redball.offset().top;
    }
  }

  componentDidMount() {
    const animate = true;

    const sentence = [];

    _.each(this.sentence.split(' '), (word, i) => {
      sentence.push(() => this.setState({ sentence: i }));
      sentence.push(() => this.setState({ sentence: i + 1 }));
      sentence.push(200);
    });

    const animation = [
      () => this.setState({ moveCircleToMiddle: true }),
      1000,
      () => this.setState({ showGrayCircle: true }),
      () => this.setState({ showMicrophone: true }),
      100,
      () => this.setState({ moveCircleToTop: true }),
      1000,
      () => this.setState({ pulseGrayCircle: true }),
      ...sentence,
      1000,
      () => this.setState({ fadeOutText: true }),
      () => this.setState({ moveCircleBackCenter: true }),
      500,
      () => this.setState({ hideMicrophone: true }),
      () => this.setState({ showMicrophone: false }),
      500,
      () => this.setState({ stickCircleToTop: true }),
      300
    ];

    if (animate === true) {
      sequence([
        ...(CONSTANTS.SHOW_ANIMATION === true ? animation : []),
        () => this.setState({ fadeOut: true }),
        1000,
        () => this.setState({ hide: true })
      ]);
    }
  }

  render() {
    const {
      moveCircleToMiddle,
      showGrayCircle,
      pulseGrayCircle,
      showMicrophone,
      moveCircleToTop,
      sentence,
      fadeOutText,
      moveCircleBackCenter,
      hideMicrophone,
      stickCircleToTop,
      fadeOut,
      hide
    } = this.state;

    return (
      <div
        {...cxs(
          styles.Wrapper,
          flex.vertical,
          flex.centerVertical,
          fadeOut === true && styles.WrapperFadeOut,
          hide === true && styles.WrapperHide
        )}
      >
        {/* Circle */}
        <div
          {...cxs(
            styles.circle,
            flex.vertical,
            flex.centerVertical,
            moveCircleToTop === true && styles.moveCircleToTop,
            moveCircleToMiddle === true && styles.moveCircleToMiddle,
            moveCircleBackCenter === true && styles.moveCircleBackCenter
          )}
        >
          {/* Red ball */}
          <div
            ref={ref => (this.redball = this.redball === undefined && ref !== null ? $(ref) : this.redball)}
            {...cx(
              'red-ball',
              css(styles.centerAbsolute),
              css(styles.redBall),
              stickCircleToTop === true && css(styles.stickRedBallToTop)
            )}
            style={dynamicStyles.redBall(this.redBallOffset)}
          />

          {/* Gray ball */}
          <div
            id="gray-ball"
            {...cxs(
              styles.centerAbsolute,
              styles.grayBall,
              showGrayCircle === true && styles.moveGrayCircle,
              pulseGrayCircle === true && styles.pulseGrayCircle,
              stickCircleToTop === true && styles.fadeoutGrayCircle
            )}
          />

          {/* Microphone */}
          <img
            id="microphone"
            {...cxs(
              styles.microphone,
              styles.centerAbsolute,
              showMicrophone === true && styles.showMicrophone,
              hideMicrophone === true && styles.hideMicrophone
            )}
            src={microphoneImg}
          />
        </div>

        {/* OK Google */}
        <div
          {...cxs(
            styles.okGoogleText,
            flex.horizontal,
            flex.wrap,
            flex.centerHorizontal,
            fadeOutText === true && styles.fadeOutText
          )}
        >
          {_.map(this.sentence.split(' '), (word, index) => {
            return (
              <div
                key={index}
                {...cxs(
                  styles.word,
                  sentence === index && styles.fadeText,
                  sentence >= index + 1 && styles.showText
                )}
              >
                {word}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default IntroAnimation;
