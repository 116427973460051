import mixins from 'stylz';
import queries from 'styles/queries';
import { StyleSheet } from 'aphrodite';

const sizes = {
  spaceBetweenWords: 2
};

const style = StyleSheet.create({
  Phrase: {
    ...mixins.padding.horizontal(40),
    ...mixins.padding.vertical(15),
    [queries.mobileLayout]: {
      ...mixins.padding.both(8, 15),
      fontSize: 15
    },
    fontSize: 16,
    minHeight: 55,
    userSelect: 'none',
    flexWrap: 'wrap',
    lineHeight: '25px',
    fontWeight: 300
  },
  PhraseFlashed: {
    // backgroundColor: '#d47270',
    // ...mixins.animate()
  },
  word: {
    ...mixins.margin.horizontal(sizes.spaceBetweenWords)
  },
  variable: {
    ...mixins.margin.both(5, sizes.spaceBetweenWords + 5),
    ...mixins.padding.both(3, 5),
    backgroundColor: 'rgb(241, 241, 241)',
    borderRadius: 3,
    textAlign: 'center',
    ...mixins.animate()
  }
});

export default style;
