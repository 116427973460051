export default [
  {
    text: 'Do a barrel roll',
    category: 23
  },
  {
    text: `What's the loneliest number?`,
    category: 23
  },
  {
    text: 'Make me a sandwich',
    category: 23
  },
  {
    text: 'sudo Make me a sandwich',
    category: 23
  },
  {
    text: 'When am i?',
    category: 23
  },
  {
    text: 'Okay Jarvis',
    category: 23
  },
  {
    text: 'Who are you?',
    category: 23
  },
  {
    text: 'How much would could a woodchuck chuck if a woodchuck could chuck wood?',
    category: 23
  },
  {
    text: 'Beam me up Scotty',
    category: 23
  },
  {
    text: 'How can entropy be reversed?',
    category: 23
  },
  {
    text: `What's %actor% 's bacon number`,
    category: 23
  },
  {
    text: `Tell me a joke`,
    category: 23
  },
  {
    text: 'Up, up, down, down, left, right, left, right',
    category: 23
  },
  {
    text: `Who's on first?`,
    category: 23
  },
  {
    text: `Go go gadget %app%`,
    category: 23
  },
  {
    text: `Askew`,
    category: 23
  },
  {
    text: `Roll a dice`,
    category: 23
  },
  {
    text: `Flip a coin`,
    category: 23
  }
];
