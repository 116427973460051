export default [
  'Star Wars: The Force Awakens',
  'Jurassic World',
  'Mad Max: Fury Road',
  'The Hunger Games: Mockingjay - Part 2',
  'Terminator Genisys',
  'Spectre',
  'Heist',
  'The Revenant',
  'The Hateful Eight',
  'The Martian',
  'Minions',
  'Insurgent',
  'In the Heart of the Sea',
  'Ant-Man',
  'Avengers: Age of Ultron',
  'The Good Dinosaur',
  'Cinderella',
  'Inside Out',
  'Chappie',
  'Jupiter Ascending'
];
