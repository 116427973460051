import _ from 'lodash';

const customizer = (objValue, srcValue) => {
  if (_.isArray(objValue) && _.isArray(srcValue)) {
    return srcValue;
  } else {
    if (_.isPlainObject(srcValue)) {
      return _.mergeWith(Object.assign({}, objValue), Object.assign({}, srcValue), customizer);
    } else {
      return srcValue;
    }
  }
};

export default customizer;
