import { scale } from 'utils/project';

const keyframeZero = {
  ...scale(0.5),
  opacity: 0
};

const keyframeFull = {
  ...scale(1),
  opacity: 1
};

export const showMicrophone = {
  '0%': keyframeZero,
  '100%': keyframeFull
};

export const hideMicrophone = {
  '0%': keyframeFull,
  '100%': {
    opacity: 0,
    scale: 0.8
  }
};
