import _ from 'lodash';
import { STATE as ENTITIES_STATE } from './entities.constants';
import customizer from 'utils/merge-customizer';
import update from 'react/lib/update';
import phrases from 'data/phrases-data';
import categories from 'data/categories-data';
import { setIndexAsKeyProperty } from 'kitze-js-helpers/src/helpers/array-helpers';

const initialState = {
  [ENTITIES_STATE.CATEGORIES]: _.keyBy(categories, 'id'),
  [ENTITIES_STATE.PHRASES]: _.keyBy(setIndexAsKeyProperty(phrases), 'id')
};

export default function entities(state = initialState, action) {
  if (action.$update) {
    return update(Object.assign({}, state), action.$update);
  }

  if (action.entities) {
    return Object.assign({}, _.mergeWith(state, action.entities, customizer));
  }

  return state;
}
