import mixins from 'stylz';
import queries from 'styles/queries';
import colors from 'styles/colors';
import { StyleSheet } from 'aphrodite';
import sharedSizes from 'styles/shared-sizes';
import { circle } from 'utils/project';

const dynamicStyles = {
  insideWrapper: opened => ({
    justifyContent: opened ? 'flex-start' : 'space-between'
  }),
  icon: {
    ...mixins.fontSizeHeight(24),
    color: 'white',
    position: 'relative',
    left: -5
  },
  iconButton: {
    color: 'white'
  }
};

const style = StyleSheet.create({
  Header: {
    height: sharedSizes.header.height.large,
    [queries.mobileLayout]: {
      height: sharedSizes.header.height.small
    },
    backgroundColor: colors.red,
    color: 'white',
    ...mixins.animate(),
    willChange: 'height',
    zIndex: 1500
  },
  HeaderCollapsed: {
    // height: sharedSizes.header.height.small
  },
  icon: {
    display: 'none',
    [queries.mobileLayout]: {
      display: 'inline-block'
    }
  },
  logo: {
    color: 'white',
    cursor: 'pointer',
    fontSize: 45,
    fontWeight: 300,
    [queries.mobileLayout]: {
      fontSize: 30,
      fontWeight: 500
    },
    [queries.smallerThanPhone]: {
      fontSize: 22
    }
  },
  leftIcon: {
    display: 'none',
    [queries.mobileLayout]: {
      display: 'inline-block'
    },
    marginRight: 10,
    marginLeft: -12
  },
  wrapper: {
    height: '100%',
    ...mixins.animate()
  },
  wrapperOpened: {},
  insideWrapper: {
    ...mixins.stretch,
    position: 'relative'
  },
  circle: {
    color: 'white',
    ...mixins.circle(50),
    ...mixins.animate(),
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 99999999,
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    },
    ':active': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
  },
  closeIcon: {
    fontSize: 24,
    color: 'white'
  },
  content: {
    zIndex: 9999999
  },
  menu: {},
  menuItem: {
    ...mixins.unstyle.link,
    ...mixins.customLink('white', true),
    ...mixins.margin.horizontal(10),
    fontWeight: 500,
    [queries.mobileLayout]: {
      display: 'none'
    }
  }
});

export { dynamicStyles };
export default style;
