import { StyleSheet } from 'aphrodite';
import queries from 'styles/queries';
import mixins from 'stylz';

const dynamicStyles = {};

const TextFieldStyles = {
  default: {
    color: 'white',
    width: '100%',
    fontWeight: 300
  },
  hint: {
    color: `rgba(255, 255, 255, 0.50)`
  },
  underline: {
    borderColor: `rgba(255, 255, 255, 0.50)`
  },
  underlineFocus: {
    borderColor: 'white'
  }
};

const style = StyleSheet.create({
  SearchBar: {
    position: 'relative',
    width: 330,
    flex: 1,
    [queries.mobileLayout]: {
      ...mixins.fixedWidth(1),
      opacity: 0,
      top: -300
    }
  },
  SearchBarOpened: {
    [queries.mobileLayout]: {
      opacity: 1,
      width: 330,
      maxWidth: 99999,
      minWidth: 0,
      display: 'flex',
      top: 0
    }
  },
  icon: {
    position: 'absolute'
  },
  clearIcon: {
    right: -6,
    bottom: 11,
    ...mixins.fontSizeHeight(22, 28)
  },
  searchIcon: {
    right: -3,
    bottom: 16,
    ...mixins.fontSizeHeight(14, 16),
    opacity: 0.5
  }
});

export { dynamicStyles, TextFieldStyles };
export default style;
