import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import promiseMiddleware from './middleware/promise-middleware';
import createDebounce from 'redux-debounced';
import { applyMiddleware, createStore } from 'redux';

export default function configureStore(initialState = {}) {
  let middlewares = [promiseMiddleware, thunk, createDebounce()];

  if (process.env.NODE_ENV !== 'production') {
    let createLogger = require('redux-logger');
    let logger = createLogger({ collapsed: true });
    middlewares = [...middlewares, logger];
  }

  let middleware = applyMiddleware(...middlewares);

  // Create final store and subscribe router in debug env ie. for devtools
  const store = middleware(createStore)(rootReducer, initialState);

  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default;

      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
