import React, { Component, PropTypes } from 'react';

//images
import profileImg from 'img/avatar_small.jpg';

//styles
import { css } from 'aphrodite';
import styles, { dynamicStyles } from './styles';
import sharedStyles from 'styles/shared-styles';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';

//components
import SocialNetworks from 'components/SocialNetworks';

//external
import { CardTitle } from 'material-ui/Card';

class ProfileCard extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const links = {
      github: (
        <a target="_blank" href="https://github.com/kitze/">
          geek
        </a>
      ),
      youtube: (
        <a target="_blank" href="https://www.youtube.com/ampoverloaded">
          a musician
        </a>
      )
    };

    return (
      <div {...cxs(styles.ProfileCard, sharedStyles.Card, flex.vertical)}>
        {/* Title */}
        <div {...cx(css(flex.horizontal))}>
          <div style={dynamicStyles.image(profileImg)} className={css(styles.image)} />
          <CardTitle title="Kitze" subtitle="@thekitze" />
        </div>

        {/* Text */}
        <div className={css(styles.text)}>
          Hello there! I'm Kitze. A {links.github} at day and {links.youtube} at night. Obsessed with good
          UI/UX, startups, JavaScript, React, and guitars. I'm teaching React{' '}
          <a target="_blank" href="https://reactacademy.io">
            at React Academy
          </a>
          <br />
          <br />
          I'm
          <a target="_blank" href="https://medium.com/@kitze">
            {' '}
            blogging
          </a>{' '}
          about JavaScript, React, webdev, and health.
          <br />
          <br />
          I love everything that's made by Google, especially Android.
          <br />
          <br />
          <a target="_blank" href="https://twitter.com/thekitze">
            Twitter
          </a>{' '}
          is my preferred social network, but you can also{' '}
          <a href="mailto:kristijan.mkd@gmail.com">shoot me an email</a> or reach me in many other ways.
        </div>

        <SocialNetworks />
      </div>
    );
  }
}

export default ProfileCard;
