export default [
  {
    text: 'When is the %sun% in $city()$ ?',
    category: 2
  },
  {
    text: 'What is the time zone in $city()$ ?',
    category: 2
  },
  {
    text: 'What time is it in $city()$ ?',
    category: 2
  },
  {
    text: 'What is the time at home?',
    category: 2
  }
];
