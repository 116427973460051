import _ from 'lodash';

import { createAction, handleActions } from 'redux-actions';
import { STATE, ACTIONS } from './search.constants';
import { setProperty } from '../../redux-helpers';
import { STATE as ROOT_STATE } from '../../rootReducer.constants';
import { STATE as APP_STATE } from '../../modules/app/app.constants';
import { FORM_NAME, fields as FORM_FIELDS } from '../../../config/forms/search-form';
export const setSearchbarOpened = createAction(ACTIONS.SET_SEARCHBAR_OPENED);

const searchValueIsEmpty = state => {
  const searchValue = _.get(state[ROOT_STATE.FORM][FORM_NAME][FORM_FIELDS.search.prop], 'value');
  return searchValue === undefined || searchValue.trim() === '';
};

const searchTextChanged = searchText => (dispatch, getState) => {
  const state = getState();

  const previousSearchText = _.get(state[ROOT_STATE.FORM][FORM_NAME], 'search.value');

  if (previousSearchText === undefined || previousSearchText.trim() === '') {
    const $scroller = state[ROOT_STATE.APP][APP_STATE.SCROLLER_DOM];
    $scroller.scrollTop(0);
  }
};

export const closeSearchBar = ifEmpty => (dispatch, getState) => {
  const shouldCheckIfSearchEmpty = ifEmpty !== true || searchValueIsEmpty(getState());

  if (shouldCheckIfSearchEmpty) {
    dispatch(changeSearchText(''));
    dispatch(setSearchbarOpened(false));
  }
};

export const changeSearchText = value => ({
  type: 'redux-form/CHANGE',
  form: 'searchForm',
  field: 'search',
  value
});

export const actions = {
  setSearchbarOpened,
  changeSearchText,
  closeSearchBar,
  searchTextChanged
};

export default handleActions(
  {
    ...setProperty(ACTIONS.SET_SEARCHBAR_OPENED, STATE.SEARCHBAR_OPENED)
  },
  {
    [STATE.SEARCHBAR_OPENED]: false
  }
);
