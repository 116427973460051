import { translateAndScale } from 'utils/project';

export const lastPosition = {
  x: 0,
  y: -70,
  scale: 0.6
};

export default {
  '0%': {
    transform: 'none'
  },
  '100%': {
    ...translateAndScale(lastPosition.x, lastPosition.y, lastPosition.scale)
  }
};
