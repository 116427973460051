import React, { Component, PropTypes } from 'react';

//styles
import { css } from 'aphrodite';
import styles from './styles';
import { cxs } from 'utils/project';
import flex from 'styles/flex';

//components
import Icon from 'components/Icon';

class CategoryHeader extends Component {
  static propTypes = {
    category: PropTypes.object.isRequired
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { category } = this.props;

    return (
      <div {...cxs(styles.CategoryHeader, flex.horizontal, flex.centerHorizontalV)}>
        <Icon className={css(styles.icon)} name={category.icon} />
        <div className={css(styles.text)}>{category.name}</div>
      </div>
    );
  }
}

export default CategoryHeader;
