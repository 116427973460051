export default [
  {
    text: 'Set timer for $number(2,59)$ minutes',
    category: 25
  },
  {
    text: 'Start countdown',
    category: 25
  }
];
