export default [
  {
    text: `Set an alarm in $number(2,59)$ minutes`,
    category: 9
  },
  {
    text: `Set an alarm for $time()$`,
    category: 9
  },
  {
    text: `Set a repeating alarm for $time()$`,
    category: 9
  },
  {
    text: `Set an alarm for $time()$ %alarmLabelText% %alarmLabel%`,
    category: 9
  },
  {
    text: `Set a repeating alarm at $time()$ for $daysOfWeek()$`,
    category: 9
  },
  {
    text: 'Show me my alarms',
    category: 9
  },
  {
    text: 'When is my next alarm?',
    category: 9
  },
  {
    text: 'Wake me up at $time()$ $sometimes(everyday)$',
    category: 9
  }
];
