export default [
  {
    text: `Show me my messages`,
    category: 14
  },
  {
    text: `Text $contactOrRelative()$ %sms%`,
    category: 14
  },
  {
    text: `Send an email to $contactOrRelative()$ %email%`,
    category: 14
  },
  {
    text: `Send a %messagingApp% message to $contactOrRelative()$`,
    category: 14
  }
];
