export default [
  {
    text: 'Add %items% to my %listname% list.',
    process: 'keep',
    category: 12
  },
  {
    text: 'Make a note: %keepNote%',
    category: 12
  },
  {
    text: 'Note to self: %keepNote%',
    category: 12
  }
];
