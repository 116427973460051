import React, { Component, PropTypes } from 'react';
import { $ref } from 'utils/project';

//styles
import { css } from 'aphrodite';
import styles from './styles';
import sharedStyles from 'styles/shared-styles';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';

//components
import ProfileCard from 'components/ProfileCard';
import ProjectsCard from 'components/ProjectsCard';

class Cards extends Component {
  componentDidMount() {
    this._scrollToTop();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.opened === true) {
      this._scrollToTop();
    }
  }

  _scrollToTop() {
    if (this.cards !== undefined) {
      setTimeout(() => {
        this.cards.scrollTop(0);
      }, 0);
    }
  }

  render() {
    const { opened } = this.props;

    return (
      <div {...$ref(this, 'cards')} {...cxs(styles.Cards, opened && styles.CardsOpened)}>
        {opened && (
          <div {...cxs(sharedStyles.Wrapper, styles.wrapper, flex.horizontal)}>
            <ProfileCard />
            <ProjectsCard />
          </div>
        )}
      </div>
    );
  }
}

export default Cards;
