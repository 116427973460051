const colors = {
  black: '#000000',
  red: '#e53935',
  transparent: 'rgba(0, 0, 0, 0)',
  gray: '#f5f5f5',
  white: '#ffffff',
  lightGray: '#e8e8e8',
  instagram: '#FB852D',
  facebook: '#3B5998'
};

export default colors;
