export default [
  {
    text: 'Show me my bills',
    category: 11
  },
  {
    text: 'Where is my package?',
    category: 11
  },
  {
    text: `Where's my hotel?`,
    category: 11
  },
  {
    text: 'Show me restaurants near my hotel',
    category: 11
  }
];
