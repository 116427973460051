export const STATE = {
  FORM: 'form',
  ROUTER: 'router',
  ENTITIES: 'entities',
  USER: 'user',
  SEARCH: 'search',
  UI: 'ui',
  APP: 'app',
  ANIMATIONS: 'animations'
};
