export default [
  {
    text: `Open %website%`,
    category: 7
  },
  {
    text: 'Open %app%',
    category: 7
  },
  {
    text: '%increaseDecrease% brightness',
    category: 7
  },
  {
    text: 'Take a picture',
    category: 7
  },
  {
    text: 'Take a selfie',
    category: 7
  },
  {
    text: 'Turn %onOff% %deviceSetting%',
    category: 7
  },
  {
    text: 'Record a video',
    category: 7
  },
  {
    text: '%increaseDecrease% volume',
    category: 7
  },
  {
    text: 'Set the volume to $number(1,15)$',
    category: 7
  },
  {
    text: 'Set the volume to full',
    category: 7
  },
  {
    text: 'Mute the volume',
    category: 7
  }
];
