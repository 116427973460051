export default [
  'YouTube',
  'Snapchat',
  'Facebook',
  'Dialer',
  'Camera',
  'Google Drive',
  'Inbox',
  'Slack',
  'Skype',
  'Instagram',
  'Hangouts',
  'Photos',
  'Telegram',
  'Reddit Sync',
  'Relay',
  'Spotify',
  'Runkeeper',
  'Runtastic'
];
