import { createAction, handleActions } from 'redux-actions';

//constants
import { ACTIONS, STATE, ANIMATION_STATUS } from './animations.constants';

export const setAnimationState = (element, status) => ({
  type: ACTIONS.SET_ANIMATION_STATE,
  element,
  status
});

export const animationEnded = element => dispatch => {
  dispatch(setAnimationState(element, ANIMATION_STATUS.ENDED));
};

export const actions = {
  animationEnded
};

export default handleActions(
  {
    [ACTIONS.SET_ANIMATION_STATE]: (state, action) => {
      return {
        ...state,
        [action.element]: action.status
      };
    }
  },
  {
    [STATE.HEADER_STATUS]: ANIMATION_STATUS.IN_PROGRESS,
    [STATE.CONTENT_STATUS]: ANIMATION_STATUS.IN_PROGRESS
  }
);
