import { StyleSheet } from 'aphrodite';
import mixins from 'stylz';
import colors from 'styles/colors';

export const sizes = {};
export const dynamicStyles = {};

export const style = StyleSheet.create({
  CategoryHeader: {
    marginBottom: 22,
    minHeight: 33
  },
  icon: {
    fontSize: 23,
    color: '#8B8B8B',
    width: 40
  },
  text: {
    color: '#7B7B7B',
    fontSize: 21,
    fontWeight: 500
  }
});

export default style;
