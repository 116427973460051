import { createAction, handleActions } from 'redux-actions';
import { STATE, ACTIONS } from './ui.constants';
import { setProperty, createDebouncedAction } from '../../redux-helpers';

export const windowResize = createDebouncedAction(ACTIONS.WINDOW_RESIZE, 3000);

export default handleActions(
  {
    ...setProperty(ACTIONS.WINDOW_RESIZE, STATE.WINDOW_SIZE)
  },
  {
    [STATE.WINDOW_SIZE]: { height: window.innerHeight, width: window.innerWidth }
  }
);
