export default {
  relative: ['father', 'mother', 'sister', 'brother'],
  sun: ['sunrise', 'sunset'],
  timeinfuture: [
    'today',
    'tomorrow',
    'tonight',
    'this afternoon',
    'wednesday',
    'thursday afternoon',
    'friday night'
  ],
  weatheritem: ['a jacket', 'an umbrella'],
  timeOfDay: ['morning', 'afternoon', 'night'],
  company: [
    'Google',
    'Apple',
    'Samsung',
    'Volkswagen',
    'Audi',
    'BMW',
    'Mercedes',
    'Lenovo',
    'Motorola',
    'HTC',
    'Asus',
    'Acer',
    'HP'
  ],
  mathOperation: ['divided by', 'multiplied by', 'plus', 'minus'],
  alarmLabel: [
    'Walk the dog',
    'Take my pills',
    'Take out laundry',
    'Turn off the oven',
    'Wake me up before you go go',
    'Wake up Green Day',
    'Pick kids from school',
    'Leave home on time'
  ],
  alarmLabelText: ['with label', 'labeled'],
  createSchedule: ['Create a', 'Schedule a', 'Add a', 'New'],
  calendarEventMeeting: ['calendar event', 'meeting'],
  eventName: ['lunch with mom', 'meeting with team', 'coffee with Tim', 'grandmas birthday'],
  onOff: ['on', 'off'],
  dayCalendar: ['day', 'calendar', 'schedule'],
  increaseDecrease: ['Increase', 'Decrease'],
  deviceSetting: ['Bluetooth', 'WiFi', 'Flaslight'],
  keepNote: [
    'update router firmware',
    'water plants',
    'feed the dog',
    'keep being awesome',
    'download the new Foo Fighters album',
    'prepare for my trip',
    'message my mom',
    'stop eating fast food'
  ],
  sayHowTo: ['Say', 'How to say'],
  business: ['Starbucks', 'Dominos', 'H&M', 'Pizza Hut', 'McDonalds'],
  sms: ['Where are you?', 'What are you doing', 'I miss you', 'How is the weather there?'],
  email: [
    'Can you send me the latest spreedsheet?',
    `Hey, you didn't give me feedback on the document`,
    `Please add the new pictures of the dog to Google Photos`,
    `Send me the presentation`
  ],
  messagingApp: ['Viber', 'Whatsapp', 'Hangouts', 'Telegram'],
  socialApp: ['Google+', 'Facebook', 'Twitter'],
  shoppingPlace: ['Wallgreens', 'Wallmart', 'Wegmans', 'Publix', 'Fareway'],
  recurringReminder: [
    'reevaluate my life',
    'throw out the trash',
    'clean the house',
    'clean my desk',
    'organize cables'
  ],
  popularPlace: [
    'Eifell Tower',
    'Tower of Pisa',
    'Old Town Square',
    'Colosseum',
    'Big Ben',
    'Tower Bridge',
    'British Museum',
    'Berlin Wall',
    'East Side Gallery',
    'Van Gogh Museum',
    'Central Park',
    'Statue of Liberty',
    'Times Square',
    'Brooklyn Bridge',
    'Chinatown'
  ],
  flight: ['AA 125', 'LH 210', 'AA 120'],
  landDepart: ['land', 'depart'],
  site: [
    'Huffington Post',
    'New York Times',
    'CNN',
    'BBC',
    'Yahoo',
    'MSN',
    'NBC News',
    'The Guardian',
    'USA Today',
    'LA Times',
    'ABCNews'
  ],
  goToSite: ['Open', 'Show me', 'Browse'],
  invention: [
    'telephone',
    'internet',
    'light bulb',
    'laptop',
    'microscope',
    'vacuum tube',
    'penicillin',
    'radio',
    'telescope',
    'airplane',
    'wheel',
    'bike',
    'transistor',
    'mobile phone',
    'smartphone',
    'quantum computer'
  ],
  playlist: ['gym', 'workout', 'jazzy', 'chill out'],
  openClosePlace: [
    'Eifell Tower',
    'Tower of Pisa',
    'Big Ben',
    'British Museum',
    'Anne Frank House',
    'Van Gogh Museum',
    'Central Park'
  ],
  businessCategory: ['computer shop', 'bakery', 'pizza place', 'gas station'],
  continent: ['Africa', 'Asia', 'Europe', 'North America', 'South America', 'Australia']
};
