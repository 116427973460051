import { StyleSheet } from 'aphrodite';
import mixins from 'stylz';
import queries from 'styles/queries';

export const sizes = {};
export const dynamicStyles = {
  image: img => ({
    ...mixins.backgroundImg(img, true)
  })
};

export const style = StyleSheet.create({
  image: {
    height: '100%',
    minWidth: 190,
    maxHeight: 600,
    backgroundPosition: -33
  },
  wrap: {
    height: '100%'
  },
  logos: {
    width: '100%',
    ...mixins.padding.horizontal(20)
  },
  logo: {
    ...mixins.size(70),
    marginRight: 5,
    marginBottom: 10
  },
  logoStylz: {
    ...mixins.size(70)
  },
  logoRedux: {
    ...mixins.size(70)
  },
  logoReact: {
    ...mixins.size(70)
  },
  logoReduxForm: {
    ...mixins.size(70)
  },
  logoMaterial: {
    ...mixins.size(70)
  },
  logoWebpack: {
    ...mixins.size(70)
  },
  bulletpoints: {
    width: '100%',
    ...mixins.padding.horizontal(10)
  },
  title: {
    marginBottom: 20,
    fontSize: 20,
    fontWeight: 300,
    color: '#525252',
    [queries.smallerTabletHeight]: {
      fontSize: 18,
      marginBottom: 15
    }
  },
  technologiesTitle: {
    marginTop: 30
  },
  text: {
    fontSize: 16
  },
  bulletpoint: {
    ...mixins.margin.both(3, 1)
  },
  bulletpointText: {},
  separator: {
    ...mixins.margin.horizontal(5),
    ...mixins.size(6),
    backgroundColor: '#61DAFB',
    borderRadius: '100%'
  },
  side: {
    flex: 1
  },
  top: {},
  bottom: {}
});

export default style;
