import React, { Component, PropTypes } from 'react';
import _ from 'lodash';

//styles
import { css } from 'aphrodite';
import styles from './styles';
import sharedStyles from 'styles/shared-styles';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';

//images
import reduxImg from 'img/redux.svg';
import reactImg from 'img/react-dark.svg';
import webpackLogo from 'img/webpack.svg';

class ProjectsCard extends Component {
  shouldComponentUpdate() {
    return false;
  }

  constructor(props) {
    super(props);
    this.bulletpoints = [
      'react',
      'redux',
      'reselect',
      'aphrodite',
      'material-ui',
      'stylz',
      'redux-form',
      'react-helmet',
      'webpack',
      'faker',
      'lodash',
      'moment'
    ];
  }

  render() {
    return (
      <div {...cxs(styles.ProjectsCard, sharedStyles.Card)}>
        <div {...cxs(styles.wrap, flex.vertical, flex.centerVerticalH)}>
          {/* About */}

          <div {...cxs(styles.top, styles.side)}>
            <div {...cx(css(styles.title))}>About the project</div>
            <div {...cx(css(styles.text))}>
              This is a list of commands that you can ask Google's voice assistant.
              <br />
              <br />
              Annoyed by the fact that every once in a while a new "complete list of google now commands"
              appears online, i decided to create this project.
              <br />
              <br />
              After few days of research i found an extensive list of over 150 commands and 1000+ variations.
              I'll try to keep it up to date, and in v2 i'll add an easy way for the community to contribute.
              <br />
              <br />
              The project will be{' '}
              <a target="_blank" href="https://github.com/kitze/ok-google">
                open sourced soon
              </a>, but if you find any bugs or issues feel free to report them on GitHub right now.
            </div>
          </div>

          {/* Tech used */}

          <div {...cxs(styles.bottom, styles.side, flex.vertical, flex.centerVertical)}>
            <div {...cxs(styles.title, styles.technologiesTitle)}>Technologies used</div>
            <div {...cxs(styles.logos, flex.horizontal, flex.centerHorizontal, flex.wrap)}>
              <img {...cx(css(styles.logo), css(styles.logoReact))} src={reactImg} alt="" />
              <img {...cx(css(styles.logo), css(styles.logoRedux))} src={reduxImg} alt="" />
              <img {...cx(css(styles.logo), css(styles.logoWebpack))} src={webpackLogo} alt="" />
            </div>

            <div {...cxs(styles.bulletpoints, flex.horizontal, flex.centerHorizontal, flex.wrap)}>
              {_.map(this.bulletpoints, (bulletpoint, i) => (
                <div key={i} {...cxs(styles.bulletpoint, flex.horizontal, flex.centerHorizontalV)}>
                  <div className={css(styles.bulletpointText)}>{bulletpoint}</div>
                  {i !== this.bulletpoints.length - 1 && <div className={css(styles.separator)} />}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectsCard;
