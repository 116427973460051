import mixins from 'stylz';
import queries from 'styles/queries';
import colors from 'styles/colors';
import { StyleSheet } from 'aphrodite';
import { sizes as FooterSizes } from 'components/Footer/styles';
import { translate3d } from 'utils/project';

const dynamicStyles = {};

const stretch = {
  position: 'relative',
  flex: 1,
  overflowY: 'hidden'
};

const styles = StyleSheet.create({
  Home: {
    backgroundColor: '#f1f1f1'
  },
  content: {
    backgroundColor: '#f5f5f5',
    ...mixins.animate(),
    ...stretch,
    ...mixins.padding.vertical(20, 39),
    [queries.mobileLayout]: {
      paddingBottom: 0
    }
  },
  contentBlurred: {
    opacity: 0
  },
  wrapper: {
    flex: 1,
    overflowY: 'hidden',
    height: '100%',
    maxWidth: 960,
    width: '100%',
    margin: 'auto',
    [queries.mobileLayout]: {
      paddingBottom: 0
    }
  },
  transition: {
    flex: 1,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    zIndex: 9999999
  },
  separator: {
    ...mixins.margin.vertical(10),
    border: '1px solid #ececec'
  },
  categoriesTitle: {
    ...mixins.padding.both(10),
    ...mixins.margin.vertical(7),
    fontSize: 20,
    fontWeight: 500,
    color: '#585858'
  }
});

export { dynamicStyles };
export default styles;
