import React, { Component, PropTypes } from 'react';

//styles
import styles, { dynamicStyles } from './styles';
import sharedStyles from 'styles/shared-styles';
import { css, StyleSheet } from 'aphrodite';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';

//components
import SearchBar from 'components/SearchBar';
import Icon from 'components/Icon';

//external
import FontIcon from 'plugins/FontIconPlugin';
import IconButton from 'material-ui/IconButton';

class Header extends Component {
  static propTypes = {
    //props
    searchbarOpened: PropTypes.bool,
    footerOpened: PropTypes.bool,
    //actions
    openDrawer: PropTypes.func,
    setSearchbarOpened: PropTypes.func,
    changeSearchText: PropTypes.func,
    closeSearchBar: PropTypes.func,
    searchTextChanged: PropTypes.func,
    openFooter: PropTypes.func,
    closeFooter: PropTypes.func
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.status !== nextProps.status ||
      this.props.searchbarOpened !== nextProps.searchbarOpened ||
      this.props.footerOpened !== nextProps.footerOpened
    );
  }

  render() {
    //props
    const { searchbarOpened, footerOpened } = this.props;

    //actions
    const {
      changeSearchText,
      closeSearchBar,
      openDrawer,
      setSearchbarOpened,
      searchTextChanged,
      openFooter,
      closeFooter
    } = this.props;

    return (
      <div id="header" {...cxs(styles.Header, footerOpened === true && styles.HeaderCollapsed)}>
        <div
          key={2}
          {...cxs(
            sharedStyles.Wrapper,
            styles.wrapper,
            footerOpened === true && styles.wrapperOpened,
            flex.horizontal,
            flex.centerHorizontalV
          )}
        >
          <div
            style={dynamicStyles.insideWrapper(searchbarOpened)}
            {...cxs(styles.insideWrapper, flex.horizontal, flex.centerHorizontalV)}
          >
            <div {...cxs(sharedStyles.LeftSide, flex.horizontal, flex.centerHorizontalV)}>
              {searchbarOpened === false && (
                <div
                  {...cxs(styles.leftIcon, footerOpened === true && sharedStyles.hidden)}
                  onClick={openDrawer}
                >
                  <IconButton iconStyle={dynamicStyles.iconButton}>
                    <FontIcon iconStyle={dynamicStyles.icon} className={'icon-menu'} />
                  </IconButton>
                </div>
              )}

              {searchbarOpened === true && (
                <div className={css(styles.leftIcon)} onClick={closeSearchBar}>
                  <IconButton iconStyle={dynamicStyles.iconButton}>
                    <FontIcon iconStyle={dynamicStyles.icon} className={'icon-arrow-left'} />
                  </IconButton>
                </div>
              )}

              {!searchbarOpened && (
                <div onClick={openFooter} {...cxs(styles.logo, footerOpened === true && sharedStyles.hidden)}>
                  OK GOOGLE
                </div>
              )}
            </div>

            <SearchBar
              opened={searchbarOpened}
              footerOpened={footerOpened}
              showIcon={!searchbarOpened}
              setSearchbarOpened={setSearchbarOpened}
              changeSearchText={changeSearchText}
              closeSearchBar={closeSearchBar}
              searchTextChanged={searchTextChanged}
            />

            {footerOpened === true && (
              <div {...cxs(styles.circle)}>
                <Icon className={css(styles.closeIcon)} name="cancel" onClick={closeFooter} />
              </div>
            )}

            {footerOpened === false && (
              <div {...cxs(styles.menu, flex.horizontal)}>
                <div className={css(styles.menuItem)} onClick={openFooter}>
                  About
                </div>
                <a
                  {...cx(css(styles.menuItem), 'typeform-share', 'button')}
                  href="https://kitze.typeform.com/to/AulE9U"
                  data-mode="1"
                  target="_blank"
                >
                  Feedback
                </a>
              </div>
            )}

            <div {...cxs(footerOpened === true && sharedStyles.hidden)}>
              {!searchbarOpened && (
                <div className={css(styles.icon)} onClick={() => setSearchbarOpened(true)}>
                  <IconButton iconStyle={dynamicStyles.iconButton}>
                    <FontIcon iconStyle={dynamicStyles.icon} className="icon-search" />
                  </IconButton>
                </div>
              )}

              {
                <div className={css(styles.icon)} onClick={openFooter}>
                  <IconButton iconStyle={dynamicStyles.iconButton}>
                    <FontIcon iconStyle={dynamicStyles.icon} className="icon-info" />
                  </IconButton>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
