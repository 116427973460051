import faker from 'faker';

import { getContact } from 'data/random-utils';
import * as randomUtils from 'data/random-utils';
import otherData from 'data/random-data/other-data';
import persons from 'data/random-data/persons';
import websites from 'data/random-data/websites';
import apps from 'data/random-data/apps';
import languages from 'data/random-data/languages';
import languagePhrases from 'data/random-data/language-phrases';
import sportTeams from 'data/random-data/sport-teams';
import artists from 'data/random-data/artists';
import albums from 'data/random-data/albums';
import books from 'data/random-data/books';
import shoppingListItems from 'data/random-data/shopping-list-items';
import movies from 'data/random-data/movies';
import genres from 'data/random-data/movie-genres';
import actors from 'data/random-data/actors';
import reminders from 'data/random-data/reminders';
import places from 'data/random-data/places';
import musicGenres from 'data/random-data/music-genres';

const randomList = {
  //simple data
  person: persons,
  website: websites,
  app: apps,
  language: languages,
  phrase: languagePhrases,
  sportsTeam: sportTeams,
  artist: artists,
  album: albums,
  book: books,
  shoppingListItem: shoppingListItems,
  movie: movies,
  genre: genres,
  actor: actors,
  location: places,
  reminder: reminders,
  musicGenre: musicGenres,
  //faker
  city: faker.address.city,
  country: faker.address.country,
  dayOfWeek: faker.date.weekday,
  contactName: getContact,
  ...randomUtils,
  ...otherData
};

export const randomData = randomList;
