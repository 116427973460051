import shoppingListItems from './shopping-list-items';

export default [
  {
    name: 'shopping',
    items: shoppingListItems
  },
  {
    name: 'home improvement',
    items: ['table saw', 'hammer', 'nails']
  },
  {
    name: 'genius app ideas',
    items: ['Unicornz', 'Sloth Hunt', 'Dragonlingo'],
    limit: 1
  }
];
