export default [
  {
    text: `%createSchedule% %calendarEventMeeting%`,
    category: 10
  },
  {
    text: `Schedule an event %eventName% %timeinfuture% at $time()$`,
    category: 10
  },
  {
    text: `What's my next appointment?`,
    category: 10
  },
  {
    text: 'Show me the appointments for %timeinfuture%',
    category: 10
  },
  {
    text: 'What does my %dayCalendar% look like on $dayOfWeek()$ ?',
    category: 10
  }
];
