import React, { PropTypes } from 'react';

//components
import { Provider } from 'react-redux';

//material-ui theme
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Home from "views/Home/container";
const muiTheme = getMuiTheme();

export default class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  render() {
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <Provider store={this.props.store}>
          <Home/>
        </Provider>
      </MuiThemeProvider>
    );
  }
}
