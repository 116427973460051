import { animation } from 'utils/project';

//animations
import moveCircleToMiddle from '../../animations/move-circle-to-middle';
import moveGrayCircle from '../../animations/move-gray-circle';
import { showMicrophone, hideMicrophone } from '../../animations/show-microphone';
import pulseGrayCircle from '../../animations/pulse-gray-cirlce';
import moveCircleToTop from '../../animations/move-circle-to-top';
import fadeOutText from '../../animations/fade-out-text';
import moveCircleBackCenter from '../../animations/move-circle-back-center';

const animations = {
  moveCircleToMiddle: {
    ...animation(moveCircleToMiddle, 1, 0, 'normal', 'forwards')
  },
  moveGrayCircle: {
    ...animation(moveGrayCircle, 1, 0, 'normal', 'forwards')
  },
  pulseGrayCircle: {
    ...animation(moveGrayCircle, 1, 0, 'normal', 'forwards')
  },
  showMicrophone: {
    ...animation(showMicrophone, 1, 0, 'normal', 'forwards')
  },
  pulseGrayCircle: {
    ...animation(pulseGrayCircle, 1, 0, 'alternate-reverse', 'forwards', '4')
  },
  moveCircleToTop: {
    ...animation(moveCircleToTop, 1, 0, 'normal', 'forwards')
  },
  fadeOutText: {
    ...animation(fadeOutText, 0.5, 0, 'normal', 'forwards')
  },
  hideMicrophone: {
    ...animation(hideMicrophone, 0.5, 0, 'normal', 'forwards')
  },
  moveCircleBackCenter: {
    ...animation(moveCircleBackCenter, 1, 0, 'normal', 'forwards')
  }
};

export default animations;
