import axios from 'axios';
import { normalize, arrayOf } from 'normalizr';
import $ from 'jquery';

export const deleteFromArray = (itemKey, loadingKey, stateProperty) => (state, action) => {
  return {
    ...state,
    [loadingKey]: false,
    [stateProperty]: state[stateProperty].filter(item => item[itemKey] !== action.value)
  };
};

export const loading = loadingKey => state => {
  return {
    ...state,
    [loadingKey]: true
  };
};

export const setLoading = (key, loadingKey) => {
  return {
    [key]: loading(loadingKey)
  };
};

export const fetched = (fetchedProperty, stateProperty, loadingProperty) => ({
  [fetchedProperty]: (state, actions) => {
    return {
      ...state,
      [stateProperty]: actions.payload,
      [loadingProperty]: false
    };
  }
});

export const promiseAsync = (type, cb) => params => {
  return {
    type,
    promise: new Promise(cb)
  };
};

export const async = (key, url, method, params, cb) => ({
  type: key,
  promise: new Promise(resolve => {
    axios[method](url, params).then(({ data: result }) => {
      if (cb !== undefined) {
        cb(resolve, result);
      } else {
        resolve(
          {
            delete: params,
            post: result[0],
            get: result
          }[method]
        );
      }
    });
  })
});

export const addToArray = (collection, loadingKey, propertyKey) => (state, action) => {
  return {
    ...state,
    ...(loadingKey && { [loadingKey]: false }),
    [collection]: [action.value, ...state[propertyKey === undefined ? collection : propertyKey]]
  };
};

export const appendToArrayHelper = (state, propertyKey, action, actionPropertyKey) => {
  return {
    ...state,
    [propertyKey]: [
      ...state[propertyKey],
      action[actionPropertyKey === undefined ? 'payload' : actionPropertyKey]
    ]
  };
};

export const appendToArray = (actionName, propertyKey, actionPropertyKey) => ({
  [actionName]: (state, action) => {
    return appendToArrayHelper(state, propertyKey, action, actionPropertyKey);
  }
});

export const addWithLoading = (key, property, loadingKey) => {
  return {
    [`${key}_INIT`]: loading(loadingKey),
    [key]: addToArray(property, loadingKey)
  };
};

export const deleteWithLoading = (key, property, loadingKey, deleteBy) => {
  return {
    [`${key}_INIT`]: loading(loadingKey),
    [key]: deleteFromArray(deleteBy, loadingKey, property)
  };
};

const replace = (property, loadingKey) => (state, action) => {
  return {
    ...state,
    [property]: action.value,
    [loadingKey]: false
  };
};

export const setProperty = (actionName, key, value) => {
  return {
    [actionName]: (state, action) => {
      return {
        ...state,
        [key]: value !== undefined ? value : action.payload
      };
    }
  };
};

export const setJqueryElement = (actionName, key, overwrite) => {
  return {
    [actionName]: (state, action) => {
      return {
        ...state,
        [key]: overwrite === true || state[key] === undefined ? $(action.payload) : state[key]
      };
    }
  };
};

export const clearProperty = (actionName, key) => {
  return {
    [actionName]: state => {
      return {
        ...state,
        [key]: undefined
      };
    }
  };
};

export const getWithLoading = (key, property, loadingKey) => {
  return {
    [`${key}_INIT`]: loading(loadingKey),
    [key]: replace(property, loadingKey)
  };
};

export const dispatchNormalized = (dispatch, data, schema, type, single = false, stateName) => {
  const normalizedData = normalize(data, single === true ? schema : arrayOf(schema));

  dispatch({
    type: type,
    entities: normalizedData.entities,
    payload: normalizedData.result,
    stateName
  });

  return normalizedData;
};

export const toggleBoolean = (actionName, key) => (state, action) => {
  return {
    [actionName]: state => {
      return {
        ...state,
        [key]: !state[key]
      };
    }
  };
};

export const createDebouncedAction = (type, debounceTime) => payload => ({
  type: type,
  payload,
  meta: {
    debounce: {
      time: debounceTime
    }
  }
});
