export default [
  {
    text: `Play some music`,
    category: 24
  },
  {
    text: `What's this song?`,
    category: 24
  },
  {
    text: `What song am i listening to?`,
    category: 24
  },
  {
    text: `Listen to my %playlist% playlist`,
    category: 24
  },
  {
    text: `Next song`,
    category: 24
  },
  {
    text: `Pause song`,
    category: 24
  },
  {
    text: `Listen to %musicGenre%`,
    category: 24
  },
  {
    text: `%listenPlay% %song% by %band%`,
    process: 'songByBand',
    category: 24
  },
  {
    text: `Listen to the %album% album`,
    category: 24
  },
  {
    text: `Listen to %artist%`,
    category: 24
  }
];
