export default [
  {
    text: `Define $word()$ `,
    category: 8
  },
  {
    text: `What's the definition of $word()$ ?`,
    category: 8
  },
  {
    text: `What's the meaning of $word()$ ?`,
    category: 8
  }
];
