import mixins from 'stylz';
import queries from 'styles/queries';
import { StyleSheet } from 'aphrodite';

const dynamicStyles = {
  phraseCategory: show => ({
    ...mixins.show(show)
  })
};

const styles = StyleSheet.create({
  Phrases: {
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    WebkitOverflowScrolling: 'touch'
  },
  phraseCategory: {
    marginBottom: 40,
    position: 'relative',
    width: '100%'
  },
  phrasesGroup: {
    boxShadow: '#c7c7c7 0px 1px 1px'
  },
  phraseWrap: {
    transition: 'all 100ms linear',
    backgroundColor: 'white'
  },
  phraseWrapEven: {
    backgroundColor: '#f9f9f9'
  }
});

export { dynamicStyles };
export default styles;
