import { StyleSheet } from 'aphrodite';
import mixins from 'stylz';
import colors from 'styles/colors';

export const sizes = {};
export const dynamicStyles = {};

export const style = StyleSheet.create({
  SidebarMenuItems: {},
  menuItem: {
    ...mixins.unstyle.link,
    height: 40,
    ...mixins.padding.both(10),
    ':active': {
      backgroundColor: '#f3f3f3'
    }
  },
  menuText: {
    ...mixins.unstyle.link,
    fontWeight: 500,
    color: '#6d6d6d'
  },
  menuIcon: {
    marginRight: 5,
    color: 'gray'
  }
});

export default style;
