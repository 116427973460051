import React, { Component } from 'react';

import Helmet from 'react-helmet';

//import favicons
import { requireAll } from 'kitze-js-helpers/src/helpers/webpack-helpers';
import { getPropertyFromAnotherArray } from 'kitze-js-helpers/src/helpers/array-helpers';
import { getDefaultFavicons } from 'kitze-js-helpers/src/helpers/mobile-helpers';

const mobileIconsContext = require.context('./../../../mobile-config', true, /.\.png/);

const iconsMap = getPropertyFromAnotherArray(
  requireAll(mobileIconsContext),
  mobileIconsContext.keys(),
  'module',
  'name'
);
const allIcons = getDefaultFavicons('./', iconsMap);

class DocumentHead extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Helmet
        title="OK Google"
        link={[
          allIcons.defaultAppleIcon,
          ...allIcons.appleIcons,
          ...allIcons.favicons
        ]}
      />
    );
  }
}

export default DocumentHead;
