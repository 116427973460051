import { translateAndScale } from 'utils/project';
import { lastPosition } from './move-circle-to-top';

export default {
  '0%': {
    ...translateAndScale(lastPosition.x, lastPosition.y, lastPosition.scale)
  },
  '100%': {
    ...translateAndScale(0, 0, 1)
  }
};
