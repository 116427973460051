import { StyleSheet } from 'aphrodite';
import mixins from 'stylz';
import queries from 'styles/queries';
import { plainFlex } from 'styles/flex';
import sharedSizes from 'styles/shared-sizes';

export const sizes = {};

export const dynamicStyles = {
  image: img => ({
    ...mixins.backgroundImg(img, true)
  }),
  buttonLabel: {
    position: 'relative',
    top: -4
  }
};

export const style = StyleSheet.create({
  ProfileCard: {
    marginBottom: sharedSizes.Wrapper.padding.default,
    marginRight: 20,
    /* Other */
    [queries.smallerThanTabletL]: {
      marginBottom: sharedSizes.Wrapper.padding.smallerThanTabletL,
      marginRight: sharedSizes.Wrapper.padding.smallerThanTabletL
    },
    [queries.smallerThanPhablet]: {
      marginBottom: sharedSizes.Wrapper.padding.smallerThanPhablet,
      marginRight: sharedSizes.Wrapper.padding.smallerThanPhablet
    },
    [queries.smallerThanPhone]: {
      marginBottom: sharedSizes.Wrapper.padding.smallerThanPhone,
      marginRight: sharedSizes.Wrapper.padding.smallerThanPhone
    }
  },
  image: {
    ...mixins.fixedWidth(100),
    ...mixins.fixedHeight(100),
    borderRadius: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 0
  },
  wrap: {
    height: '100%'
  },
  text: {
    ...mixins.margin.vertical(20),
    flex: 1
  }
});

export default style;
