export const STATE = {
  CURRENT: 'currentUser',
  AFTER_LOGIN: 'afterLogin',
  LOADING: 'loadingUser',
  ERROR: 'loginError'
};

export const ACTIONS = {
  SET_USER: 'SET_USER',
  LOADING_LOGIN: 'LOADING_LOGIN',
  LOGOUT: 'LOGOUT',
  LOGOUT_INIT: 'LOGOUT_INIT',
  SET_ERROR: 'SET_ERROR'
};
