import { StyleSheet } from 'aphrodite';
import mixins from 'stylz';
import colors from 'styles/colors';

export const sizes = {};
export const dynamicStyles = {};

export const style = StyleSheet.create({
  NoPhrasesFound: {
    ...mixins.size('100%')
  },
  icon: {
    fontSize: 99,
    marginBottom: 20,
    color: '#a9a9a9'
  },
  text: {
    fontSize: 40,
    color: 'gray',
    textAlign: 'center'
  }
});

export default style;
