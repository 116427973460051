import reduxFormCreator from 'redux/redux-form-creator';

export const FORM_NAME = 'searchForm';

export const fields = {
  search: {
    prop: 'search',
    title: 'Search'
  }
};

export const searchForm = new reduxFormCreator(FORM_NAME, fields);
