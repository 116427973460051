import React, { Component, PropTypes } from 'react';
import _ from 'lodash';
import { randomData } from 'data/random-data';

//styles
import { css } from 'aphrodite';
import styles from './styles';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';

const placeholderRegex = /^[%](.*)[%]$/;
const functionRegex = /[$]([^(]+)(\(.*\))?[$]/;

class Phrase extends Component {
  static propTypes = {
    phrase: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.nextWords = [];
    this.wordIndex = 0;
    this.state = {
      flashed: 0,
      phrase: this._generatePhrase()
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.phrase !== nextState.phrase && this.state.flashed !== nextState.flashed;
  }

  _generatePhrase() {
    return this._renderPhrase(this.props.phrase);
  }

  _processPlaceholder(command) {
    return _.sample(randomData[command]);
  }

  _processCommand(command, params) {
    params = params.slice(1, params.length - 1).split(',');
    const com = randomData[command];
    if (_.isFunction(com)) {
      return com(...params);
    }
  }

  _isCommand(text) {
    var result = placeholderRegex.test(text) || functionRegex.test(text);
    return result;
  }

  _getCommand(text) {
    const placeholderTest = placeholderRegex.exec(text);
    if (placeholderTest !== null) {
      return this._processPlaceholder(placeholderTest[1]);
    } else {
      const commandTest = functionRegex.exec(text);
      return this._processCommand(commandTest[1], commandTest[2]);
    }
  }

  _renderVariableWord(text, replacement) {
    const gotCommand = this._getCommand(text);

    if (replacement === undefined && gotCommand === undefined) {
      return <noscript key={this.wordIndex} />;
    }

    return (
      <span key={this.wordIndex} className={`phrase__variable ${css(styles.variable)}`}>
        {replacement || gotCommand}
      </span>
    );
  }

  _renderCurrentWords() {
    const result = this.nextWords.join(' ');
    this.nextWords = [];
    return <span key={this.wordIndex}> {result} </span>;
  }

  _renderPhrase(phrase) {
    let replacements = [];
    let results = [];

    const splitted = phrase.text.split(' ');
    const variables = _.filter(splitted, word => this._isCommand(word));

    if (variables.length === 0) {
      return <span> {phrase.text} </span>;
    }

    if (phrase.process !== undefined) {
      const fn = randomData[phrase.process];
      replacements = fn(variables);
    }

    _.each(splitted, (word, index) => {
      this.wordIndex++;

      if (this._isCommand(word)) {
        results.push(this._renderCurrentWords(index));
        this.wordIndex++;
        results.push(this._renderVariableWord(word, replacements.shift()));
      } else {
        this.nextWords.push(word);
      }
    });

    if (this.nextWords.length > 0) {
      results.push(this._renderCurrentWords());
    }

    return results;
  }

  _update = () => {
    if (this.lastTimeout !== undefined) {
      clearInterval(this.lastTimeout);
    }
    this.setState({ flashed: this.state.flashed + 1, phrase: this._generatePhrase() });
    this.lastTimeout = setTimeout(() => {
      this.setState({ flashed: 0 });
    }, 200);
  }

  render() {
    const { flashed, phrase: phraseHtml } = this.state;
    const { handheld } = this.props;

    const desktopProps = {
      onMouseEnter: this._update
    };

    return (
      <div
        onClick={this._update}
        {...!handheld && desktopProps}
        {...cx(
          css(styles.Phrase),
          css(flex.horizontal),
          css(flex.centerHorizontalV),
          flashed > 0 && 'phrase--flashed'
        )}
      >
        {phraseHtml}
      </div>
    );
  }
}

export default Phrase;
