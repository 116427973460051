export default [
  'Manchester United',
  'Tottenham Hotspur',
  'A Bournemouth',
  'Aston Villa',
  'Everton',
  'Watford',
  'Leicester City',
  'Sunderland A',
  'Norwich City',
  'Crystal Palace',
  'Chelsea',
  'Swansea City',
  'Newcastle United',
  'Southampton',
  'Arsenal',
  'West Ham United',
  'Stoke City',
  'Liverpool',
  'West Bromwich Albion',
  'Manchester City '
];
