import peopleRelationships from './phrase-categories/people-and-relationships-category';
import time from './phrase-categories/time-category';
import weather from './phrase-categories/weather-category';
import stock from './phrase-categories/stock-category';
import conversion from './phrase-categories/conversion-category';
import math from './phrase-categories/math-category';
import deviceControl from './phrase-categories/device-control-category';
import define from './phrase-categories/define-category';
import alarms from './phrase-categories/alarms-category';
import calendar from './phrase-categories/calendar-category';
import gmail from './phrase-categories/gmail-category';
import googleKeep from './phrase-categories/google-keep-category';
import contactsCalls from './phrase-categories/contacts-calls-category';
import messages from './phrase-categories/messages-category';
import social from './phrase-categories/social-category';
import translation from './phrase-categories/translation-category';
import reminders from './phrase-categories/reminders-category';
import mapsNavigation from './phrase-categories/maps-navigation-category';
import sports from './phrase-categories/sports-category';
import flightTravel from './phrase-categories/flight-travel-category';
import webBrowsing from './phrase-categories/web-browsing-category';
import movies from './phrase-categories/movies-category';
import easterEggs from './phrase-categories/easter-eggs-category';
import music from './phrase-categories/music-category';
import timerStopwatch from './phrase-categories/timer-stopwatch-category';
import other from './phrase-categories/other-category';

const phrases = [
  ...peopleRelationships,
  ...time,
  ...calendar,
  ...weather,
  ...stock,
  ...conversion,
  ...math,
  ...deviceControl,
  ...define,
  ...alarms,
  ...gmail,
  ...googleKeep,
  ...contactsCalls,
  ...messages,
  ...social,
  ...translation,
  ...reminders,
  ...mapsNavigation,
  ...sports,
  ...flightTravel,
  ...webBrowsing,
  ...movies,
  ...easterEggs,
  ...music,
  ...timerStopwatch,
  ...other
];

export default phrases;
