export default [
  {
    text: `What's the weather like?`,
    category: 3
  },
  {
    text: 'Do i need %weatheritem% for %timeinfuture% ?',
    category: 3
  },
  {
    text: `What's the weather going to be like in $city()$ %timeinfuture% ?`,
    category: 3
  },
  {
    text: `What's the temperature outside?`,
    category: 3
  },
  {
    text: `Is there a chance of rain %timeinfuture% ?`,
    category: 3
  }
];
