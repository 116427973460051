import mixins from 'stylz/src/index';
import { StyleSheet } from 'aphrodite';
import colors from 'styles/colors';
import queries from 'styles/queries';

export const sizes = {
  height: {
    default: 35,
    hovered: 40
  }
};

const dynamicStyles = {};

const style = StyleSheet.create({
  Footer: {
    height: sizes.height.default,
    backgroundColor: '#e4e4e4',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    transition: 'all 100ms linear',
    transitionProperty: 'height, background',
    cursor: 'pointer',
    opacity: 1,
    ':hover': {
      backgroundColor: '#dedede',
      height: sizes.height.hovered
    },
    [queries.mobileLayout]: {
      display: 'none'
    }
  },
  FooterOpened: {
    opacity: 0
  },
  wrapper: {
    justifyContent: 'flex-end'
  },
  text: {
    fontSize: 18,
    color: '#8B8B8B'
  },
  love: {
    // fontSize: 18,
    color: colors.red,
    // fontWeight: 400,
    ...mixins.margin.horizontal(7)
  },
  close: {
    ...mixins.cornerOffset(15),
    ...mixins.size(50),
    ...mixins.fontSizeHeight(25, 20),
    position: 'absolute',
    padding: 15,
    backgroundColor: 'red',
    color: 'white',
    borderRadius: '100%',
    textAlign: 'center',
    border: 0,
    outline: 'none'
  }
});

export { dynamicStyles };
export default style;
