const sizes = {
  leftSideWidth: {
    bigSize: 300,
    smallSize: 270
  },
  rightSide: {
    offset: {
      bigSize: {
        right: 30,
        left: 10
      },
      smallSize: 10,
      equalSize: 35
    }
  },
  header: {
    height: {
      large: 80,
      small: 60
    }
  },
  Wrapper: {
    padding: {
      default: 50,
      smallerThanTabletL: 20,
      smallerThanPhablet: 15,
      smallerThanPhone: 10
    }
  }
};

export default sizes;
