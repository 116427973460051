export default [
  'To Kill a Mockingbird',
  'Pride and Prejudice',
  'Animal Farm',
  'Gone with the Wind',
  'The Book Thief',
  'The Giving Tree',
  'The Fault in Our Stars',
  'Wuthering Heights',
  'Memoirs of a Geisha',
  'Romeo and Juliet',
  'The Picture of Dorian Gray',
  'Lord of the Flies',
  'The Alchemist',
  "The Time Traveler's Wife",
  'Crime and Punishment',
  'Jane Eyre',
  "Charlotte's Web",
  'The Help',
  'Of Mice and Men',
  'Dracula',
  'Brave New World',
  'The Princess Bride',
  'One Hundred Years of Solitude',
  'The Great Gatsby',
  'The Secret Garden',
  'A Thousand Splendid Suns',
  'The Lovely Bones',
  'Where the Wild Things Are',
  'Green Eggs and Ham',
  'The Catcher in the Rye',
  'Water for Elephants',
  'Life of Pi',
  'A Tale of Two Cities',
  'Fahrenheit 451',
  'The Odyssey',
  'The Little Prince',
  'The Outsiders',
  'Lolita',
  'Slaughterhouse-Five',
  'Catch-22',
  'Frankenstein',
  'The Stand',
  'Watership Down',
  "My Sister's Keeper",
  "One Flew Over the Cuckoo's Nest",
  'The Kite Runner',
  'The Bell Jar',
  "The Handmaid's Tale",
  'A Tree Grows in Brooklyn',
  'Rebecca',
  'Anna Karenina',
  'The Road',
  'The Color Purple',
  'A Clockwork Orange',
  'The Brothers Karamazov',
  'The Poisonwood Bible',
  'Matilda',
  'Atlas Shrugged',
  'Siddhartha',
  'Don Quixote',
  'Fahrenheit 451',
  'A Prayer for Owen Meany',
  'The Old Man and the Sea',
  'Great Expectations',
  'Moby-Dick; or, The Whale',
  'The Complete Stories and Poems',
  'The Red Tent',
  'The Secret Life of Bees'
];
