export default [
  'google.com',
  'nytimes.com',
  'androidpolice.com',
  'androidcentral.com',
  'androidauthority.com',
  'phandroid.com',
  'talkandroid.com',
  'androidheadlines.com',
  'droid-life.com',
  'gmail.com',
  'youtube.com'
];
