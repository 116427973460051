import { StyleSheet } from 'aphrodite';
import mixins from 'stylz';
import colors from 'styles/colors';
import { sizes as footerSizes } from 'components/Footer/styles';
import queries from 'styles/queries';
import sharedSizes from 'styles/shared-sizes';

export const sizes = {
  Cards: {
    verticalPadding: {
      large: 20,
      small: 5
    }
  }
};

export const dynamicStyles = {};

export const style = StyleSheet.create({
  Cards: {
    zIndex: 1000,
    display: 'none',
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    maxHeight: 800,

    ...mixins.padding.vertical(
      sizes.Cards.verticalPadding.large + sharedSizes.header.height.large,
      sizes.Cards.verticalPadding.large
    ),
    /* Other */
    [queries.smallerThanTabletL]: {
      ...mixins.padding.vertical(
        sharedSizes.Wrapper.padding.smallerThanTabletL + sharedSizes.header.height.small,
        sharedSizes.Wrapper.padding.smallerThanTabletL
      )
    },
    [queries.smallerThanPhablet]: {
      ...mixins.padding.vertical(
        sharedSizes.Wrapper.padding.smallerThanPhablet + sharedSizes.header.height.small,
        sharedSizes.Wrapper.padding.smallerThanPhablet
      )
    },
    [queries.smallerThanPhone]: {
      ...mixins.padding.vertical(
        sharedSizes.Wrapper.padding.smallerThanPhone + sharedSizes.header.height.small,
        sharedSizes.Wrapper.padding.smallerThanPhone
      )
    },
    [queries.oneColumnCards]: {
      overflowY: 'scroll',
      WebkitOverflowScrolling: 'touch'
    }
  },
  CardsOpened: {
    display: 'flex'
  },
  wrapper: {
    height: '100%',
    [queries.oneColumnCards]: {
      display: 'inline-block',
      height: 'auto'
    }
  }
});

export default style;
