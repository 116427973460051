import React from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';
import Root from './containers/Root';
import configureStore from './redux/configureStore';
import { windowResize } from './redux/modules/ui/ui.redux';
import { checkDevice } from './redux/modules/app/app.redux';

const initialState = window.__INITIAL_STATE__;
const store = configureStore(initialState);

const $window = $(window);
store.dispatch(checkDevice());

//store width and height in redux store
$window.on('resize', () => {
  store.dispatch(windowResize({ width: window.innerWidth, height: window.innerHeight }));
});

import 'styles/scss/core.scss';

ReactDOM.render(
  <Root style={{ height: '100%' }} store={store} />,
  document.getElementById('root')
);
