import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { searchForm } from 'config/forms/search-form';

//styles
import styles, { TextFieldStyles } from './styles';
import { cx, cxs } from 'utils/project';
import { css } from 'aphrodite';
import flex from 'styles/flex';
import sharedStyles from 'styles/shared-styles';

//external
import TextField from 'material-ui/TextField';
import Icon from 'components/Icon';

class SearchBar extends Component {
  static propTypes = {
    opened: PropTypes.bool,
    footerOpened: PropTypes.bool,
    showIcon: PropTypes.bool,
    changeSearchText: PropTypes.func,
    searchTextChanged: PropTypes.func,
    fields: PropTypes.shape({
      search: PropTypes.object
    })
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.opened !== true && nextProps.opened === true) {
      this.refs.myInput.focus();
    }
  }

  shouldComponentUpdate(nextProps) {
    return this.opened !== nextProps.opened || this.props.fields.search.text !== nextProps.fields.search.text;
  }

  _focusField() {
    this.refs.myInput.focus();
  }

  _clearField = () => {
    this.props.changeSearchText('');
    this._focusField();
  }

  render() {
    //props
    const { fields: { search }, opened, showIcon, footerOpened } = this.props;

    //actions
    const { closeSearchBar, searchTextChanged } = this.props;

    //logic
    const searchValue = search.value ? search.value.trim() : '';
    const showClearFieldIcon = showIcon === false && searchValue !== '';

    return (
      <div
        {...cxs(
          flex.horizontal,
          styles.SearchBar,
          opened && styles.SearchBarOpened,
          sharedStyles.RightSideMargin,
          footerOpened === true && sharedStyles.hidden
        )}
      >
        <TextField
          {...search}
          ref="myInput"
          hintText="Search phrases..."
          fullWidth={true}
          autoComplete="off"
          onChange={e => {
            searchTextChanged(e.target.value);
            search.onChange(e);
          }}
          onBlur={() => closeSearchBar(true)}
          inputStyle={TextFieldStyles.default}
          hintStyle={TextFieldStyles.hint}
          underlineStyle={TextFieldStyles.underline}
          underlineFocusStyle={TextFieldStyles.underlineFocus}
        />

        {showIcon && <Icon {...cxs(styles.icon, styles.searchIcon)} name="search" />}
        {showClearFieldIcon && (
          <Icon name="cancel" {...cxs(styles.icon, styles.clearIcon)} onClick={this._clearField} />
        )}
      </div>
    );
  }
}

export default reduxForm(searchForm.getForm())(SearchBar);
