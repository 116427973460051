import pluralize from 'pluralize';
import _ from 'lodash';
import capitalize from 'underscore.string/capitalize';
import faker from 'faker';

//data
import measurePairs from 'data/random-data/measure-pairs';
import googleKeepLists from 'data/random-data/google-keep-lists';
import relatives from 'data/random-data/relatives';
import reminders from 'data/random-data/reminders';
import randomCurrencies from 'data/random-data/currencies';
import shoppingCategoryList from 'data/random-data/shopping-category-list';
import locationPhrases from 'data/random-data/location-phrases';
import places from 'data/random-data/places';
import bandsAndSongs from 'data/random-data/bands-and-songs';
import ampm from 'data/random-data/am-pm';
import genres from 'data/random-data/movie-genres';

export const pluralizeSecond = (randomNumber, arr) => [pluralize(arr[0], randomNumber), pluralize(arr[1])];

export const addLeadingZero = number => (number >= 1 && number < 10 ? `0${number}` : number);

export const getContact = () => `${faker.name.firstName()} ${faker.name.lastName()}`;

export const number = (from, to) => _.random(from || 1, to || 10000);

export const daysOfWeek = () => _.times(_.random(1, 2), faker.date.weekday).join(' and ');

export const sometimes = text => (_.sample([true, false]) ? text : undefined);

export const contactOrRelative = () => _.sample([getContact(), _.sample(relatives)]);

export const genreCapitalized = () => capitalize(_.sample(genres));

export const measure = () => {
  const sampleMeasures = _.sampleSize(_.sample(measurePairs), 2);
  const randomNumber = _.random(1, 1000);
  return [randomNumber, ...pluralizeSecond(randomNumber, sampleMeasures)];
};

export const currencies = () => {
  const sampleCurrencies = _.sampleSize(randomCurrencies, 2);
  const randomNumber = _.random(1, 1000);
  return [randomNumber, ...pluralizeSecond(randomNumber, sampleCurrencies)];
};

export const word = () => {
  const words = [faker.lorem.word(), faker.hacker.noun(), faker.hacker.verb(), faker.hacker.adjective()];
  return _.sample(words);
};

export const time = () => {
  let number = addLeadingZero(_.random(1, 12));
  const minute = addLeadingZero(_.random(1, 60));
  return `${number}:${minute} ${_.sample(ampm)}`;
};

export const keep = () => {
  const list = _.sample(googleKeepLists);
  const itemsLimit = list.limit || (list.items.length > 3 ? 3 : list.items.length);
  const sampleItems = _.sampleSize(list.items, _.random(1, itemsLimit)).join(' and ');
  return [sampleItems, list.name];
};

export const locationReminder = () => {
  const reminder = _.sample(reminders);
  const place = _.sample(places);
  const phrase = _.sample(locationPhrases);
  const suffix = phrase.locations[place];
  const finalPhrase = `${phrase.text}${suffix ? ` ${suffix}` : ''}`;
  return [reminder, finalPhrase, place];
};

export const shoppingCategory = () => {
  const category = _.sample(shoppingCategoryList);
  return [_.sample(category.items), category.name];
};

export const songByBand = () => {
  const listenPlay = ['Listen to', 'Play'];
  const band = _.sample(bandsAndSongs);
  return [_.sample(listenPlay), _.sample(band.songs), band.name];
};

export const movieTime = () => {
  const list = ['today', 'tomorrow', `on ${faker.date.weekday()}`];

  return [_.sample(list)];
};
