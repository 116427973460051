export default [
  {
    text: `%sayHowTo% %phrase% in %language% ?`,
    category: 16
  },
  {
    text: `Translate %phrase% to %language% ?`,
    category: 16
  }
];
