import _ from 'lodash';

class reduxFormCreator {
  constructor(title, fields, validation, asyncValidation) {
    this.title = title;
    this.fields = fields;
    this.validation = validation;
    this.asyncValidate = asyncValidation;
    this.asyncBlurFields = _.filter(this.fields, field => field.async).map(field => field.prop);

    this.childrenProps = _.flatten(
      _.map(fields, field => {
        if (!field.childProps) {
          return field.prop;
        }
        return field.childProps.map(childProp => `${field.prop}.${childProp}`);
      })
    );
  }

  getTitle() {
    return this.title;
  }

  getFields() {
    return this.fields;
  }

  getFieldsAsArray() {
    return _.map(this.fields, field => field.prop);
  }

  getChildPropsFrom(prop) {
    return this.fields[prop].childProps;
  }

  getForm() {
    return {
      form: this.title,
      fields: this.childrenProps,
      validate: this.validation,
      returnRejectedSubmitPromise: true,
      touchOnBlur: true,
      asyncValidate: this.asyncValidate,
      asyncBlurFields: this.asyncBlurFields
    };
  }
}

export default reduxFormCreator;
