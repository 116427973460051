import { StyleSheet } from 'aphrodite';

export const plainFlex = {
  horizontal: {
    display: 'flex',
    flexDirection: 'row'
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  centerHorizontal: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  centerHorizontalH: {
    justifyContent: 'center'
  },
  centerHorizontalV: {
    alignItems: 'center'
  },
  centerVertical: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  centerVerticalH: {
    alignItems: 'center'
  },
  centerVerticalV: {
    justifyContent: 'center'
  },
  spaceAround: {
    justifyContent: 'space-around'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  }
};

const flexsheet = StyleSheet.create(plainFlex);

export default flexsheet;
