export default [
  'Catherine Missal',
  'Monica Bellucci',
  'Michael Doven',
  'Jasmine Reate',
  'Tom Cruise',
  'Scarlett Johansson',
  'Anna Raadsveld',
  'Jason Statham',
  'Emilia Clarke',
  'Wentworth Miller',
  'Dwayne Johnson',
  'Rose Byrne',
  'Rachel McAdams',
  'Michelle Rodriguez',
  'Tom Hanks'
];
