import { StyleSheet } from 'aphrodite';
import mixins from 'stylz';
import colors from 'styles/colors';
import animations from './animations';
import { translate3d } from 'utils/project';
import sharedSizes from 'styles/shared-sizes';
import devices from 'stylz/src/devices';

export const sizes = {
  redBall: {
    size: 200
  }
};

export const dynamicStyles = {
  redBall: offsetTop => {
    const isMobile = window.innerWidth <= devices.tabletS.width;
    const headerHeight =
      isMobile === true ? sharedSizes.header.height.small : sharedSizes.header.height.large;
    const difference = sizes.redBall.size / 2 - headerHeight / 2;

    return {
      ...(offsetTop !== undefined && translate3d(0, -(offsetTop + difference), 0)),
      width: offsetTop !== undefined ? '100%' : sizes.redBall.size,
      height: offsetTop !== undefined ? headerHeight : sizes.redBall.size
    };
  }
};

const shared = {
  absoluteMargin: {
    ...mixins.absolute(),
    margin: 'auto'
  }
};

export const style = StyleSheet.create({
  Wrapper: {
    ...mixins.animate(),
    ...shared.absoluteMargin,
    zIndex: 999999,
    backgroundColor: colors.white
  },
  WrapperFadeOut: {
    opacity: 0
  },
  WrapperHide: {
    display: 'none'
  },
  circle: {
    // ...mixins.size(300),
    height: 300,
    width: '100%',
    ...mixins.animate()
  },
  microphone: {
    ...mixins.size(100),
    ...mixins.animate(),
    zIndex: 3
  },
  centerAbsolute: {
    ...shared.absoluteMargin,
    borderRadius: '100%'
  },
  redBall: {
    backgroundColor: colors.red,
    zIndex: 2,
    ...mixins.animate()
  },
  grayBall: {
    backgroundColor: colors.gray,
    ...mixins.size(250),
    zIndex: 1,
    ...mixins.animate()
  },
  okGoogleText: {
    fontSize: 25,
    ...mixins.padding.horizontal(20)
  },
  word: {
    opacity: 0,
    ...mixins.margin.horizontal(2.5),
    marginBottom: 5
  },
  fadeText: {
    opacity: 0.25
  },
  showText: {
    opacity: 1
  },
  stickRedBallToTop: {
    ...mixins.animate(),
    borderRadius: 0
  },
  fadeoutGrayCircle: {
    height: 1000,
    borderRadius: 0,
    width: '100%'
  },
  ...animations
});

export default style;
