import React, { Component, PropTypes } from 'react';
import _ from 'lodash';
import socialNetworks from 'config/social-networks';

//styles
import { css } from 'aphrodite';
import styles, { dynamicStyles } from './styles';
import { cxs } from 'utils/project';
import flex from 'styles/flex';

//components
import Icon from 'components/Icon';

class SocialNetworks extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div {...cxs(styles.socialNetworks, flex.horizontal, flex.wrap)}>
        {_.map(socialNetworks, (network, i) => (
          <a
            {...cxs(
              styles.socialButton,
              styles[`SocialNetwork${network.name}`],
              flex.horizontal,
              flex.centerHorizontal
            )}
            key={i}
            href={network.url}
            target="_blank"
          >
            <Icon className={css(styles.socialIcon)} name={network.icon} />
          </a>
        ))}
      </div>
    );
  }
}

export default SocialNetworks;
