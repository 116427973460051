import React, { Component, PropTypes } from 'react';

//styles
import { css } from 'aphrodite';
import styles from './styles';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';

//components
import Icon from 'components/Icon';

class SidebarMenuItems extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { openFooterCloseDrawer, closeDrawer } = this.props;

    return (
      <div className={css(styles.SidebarMenuItems)}>
        <div
          {...cxs(styles.menuItem, flex.horizontal, flex.centerHorizontalV)}
          onClick={openFooterCloseDrawer}
        >
          <Icon name="info" className={css(styles.menuIcon)} />
          <div className={css(styles.menuText)}>About</div>
        </div>

        <a
          href="mailto:kristijan.mkd@gmail.com"
          onClick={closeDrawer}
          {...cxs(styles.menuItem, flex.horizontal, flex.centerHorizontalV)}
        >
          <Icon name="mail-alt" className={css(styles.menuIcon)} />
          <div className={css(styles.menuText)}>Contact</div>
        </a>

        <a
          {...cx(css(styles.menuItem), css(flex.horizontal), css(flex.centerHorizontalV), 'typeform-share')}
          href="https://kitze.typeform.com/to/AulE9U"
          data-mode="1"
          target="_blank"
        >
          <Icon name="comment" className={css(styles.menuIcon)} />
          <div className={css(styles.menuText)}>Feedback</div>
        </a>
      </div>
    );
  }
}

export default SidebarMenuItems;
