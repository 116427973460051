import React, { Component } from 'react';

//styles
import styles from './style';
import { css } from 'aphrodite';
import { cx, cxs } from 'utils/project';
import flex from 'styles/flex';
import sharedStyles from 'styles/shared-styles';

//components
import Header from 'components/Header';
import Footer from 'components/Footer';
import Sidebar from 'components/Sidebar';
import Phrases from 'components/Phrases';
import IntroAnimation from 'components/IntroAnimation/container';
import Cards from 'components/Cards';
import DocumentHead from 'components/DocumentHead';
import SidebarMenuItems from 'components/SidebarMenuItems';

//external
import Drawer from 'material-ui/Drawer';
import Ad from 'components/Ad';

export class Home extends Component {
  render() {
    const {
      actions,
      categories,
      phrasesByCategory,
      filterText,
      searchResults,
      searchbarOpened,
      drawerIsOpen,
      handheld,
      footerOpened
    } = this.props;

    const filterTextIsNotEmpty = filterText !== undefined && filterText.trim() !== '';

    return (
      <div {...cx(css(styles.Home), css(flex.vertical), 'u-stretch-and-fix')}>
        <DocumentHead />

        <IntroAnimation setFooterStatus={actions.setFooterStatus} />

        <Cards opened={footerOpened} setFooterStatus={actions.setFooterStatus} />

        <Header
          searchbarOpened={searchbarOpened}
          openDrawer={() => actions.openDrawer(true)}
          setSearchbarOpened={actions.setSearchbarOpened}
          changeSearchText={actions.changeSearchText}
          closeSearchBar={actions.closeSearchBar}
          searchTextChanged={actions.searchTextChanged}
          openFooter={actions.openFooter}
          closeFooter={actions.closeFooter}
          footerOpened={footerOpened}
        />

        <Drawer
          className={css(!footerOpened && styles.drawer)}
          docked={false}
          width={250}
          open={drawerIsOpen}
          onRequestChange={open => actions.openDrawer(open)}
        >
          <SidebarMenuItems
            openFooterCloseDrawer={actions.openFooterCloseDrawer}
            closeDrawer={actions.closeDrawer}
          />

          <hr className={css(styles.separator)} />

          <div className={css(styles.categoriesTitle)}> Categories</div>

          <Sidebar selectCategory={actions.selectCategory} categories={categories} mobile={true} />
        </Drawer>

        <div id="content" {...cxs(styles.content, flex.vertical, footerOpened && styles.contentBlurred)}>
          <div {...cxs(sharedStyles.Wrapper, styles.wrapper, flex.horizontal)}>
            {!handheld && (
              <Sidebar
                selectCategory={actions.selectCategory}
                categories={categories}
                collapsed={filterTextIsNotEmpty}
                mobile={false}
              />
            )}

            <Phrases
              filterTextIsNotEmpty={filterTextIsNotEmpty}
              searchResults={searchResults}
              phrasesByCategory={phrasesByCategory}
              setCategoryDom={actions.setCategoryDom}
              setScrollerDom={actions.setScrollerDom}
              handheld={handheld}
            />
          </div>
        </div>

        <Footer opened={footerOpened} setFooterStatus={actions.setFooterStatus} />
      </div>
    );
  }
}

export default Home;
